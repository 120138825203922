import { useContext, useEffect, useState } from "react";
import useFile from "./useFile";
import { approveSubscription, getFileEmails, searchFileDownloads } from "../api";
import { UserContext } from "../context";

export default function useFileEmails(){
    const fileData = useFile();
    const { fileId, loading, dispatch } = fileData;
    const { filesEmails } = useContext(UserContext);

    const [state, setState] = useState({
        data: {
            items: [],
            hasMore: true,
            page: 1
        },
        searchData: {
            items: [],
            hasMore: true,
            page: 1
        },
        isSearch: false,
        search: {
            value: "",
            prevSearch: null,
        },
        firstApiCallAlreadyMade: false
    });

    const { search, isSearch } = state;
    const downloads = (isSearch ? state.searchData : state.data) || {};
    const { hasMore, items: data, page } = downloads;
    const updating = filesEmails.updating || new Map();

    useEffect(()=> {
        if(!fileId || loading || state?.firstApiCallAlreadyMade) return;

        async function getData(){
            const emails = await getFileEmails(fileId, 1, dispatch);

            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    items: Array.isArray(emails?.data) ? emails.data : prev.data.items,
                    hasMore: !!emails.hasMore,
                    page: emails.page || 1,
                },
                isSearch: false,
                firstApiCallAlreadyMade: true
            }));
        };

        getData();
    }, [fileId, loading, state?.firstApiCallAlreadyMade, dispatch]);

    const setSearch = (value)=> {
        setState(prev => ({
            ...prev,
            search: {
                ...prev.search,
                value
            }
        }))
    }

    const searchHandler = ()=> {
        if(loading || (state.isSearch && state.search.value.trim() === state.search.prevSearch?.trim())){
            return;
        };

        if(state.search.value.trim().length === 0){
            resetMode();
            return;
        }

        searchFileDownloads(fileId, search.value.trim(), 1, dispatch)
            .then(emails => {
                if(Array.isArray(emails?.data)){
                    setState(prev => ({
                        ...prev,
                        searchData: {
                            ...prev.searchData,
                            items: emails.data,
                            hasMore: emails.hasMore,
                            page: emails.page
                        },
                        isSearch: true,
                        firstApiCallAlreadyMade: true,
                        search: {
                            ...prev.search,
                            prevSearch: prev.search.value
                        }
                    }));
                }
            });
    }

    const resetMode = ()=> {
        if(loading) return;

        setState(prev => ({
            ...prev,
            isSearch: false,
            searchData: {
                items: [],
                hasMore: true,
                page: 1
            },
            search: {
                ...prev.search,
                prevSearch: null
            }
        }));
    }

    const loadMore = ()=> {
        if(loading || !hasMore) return;

        isSearch ? search.length > 0 && 
            searchFileDownloads(fileId, search.prevSearch, page, dispatch)
                .then(emails => {
                    if(Array.isArray(emails?.data)){
                        setState(prev => ({
                            ...prev,
                            searchData: {
                                ...prev.searchData,
                                items: prev.searchData.items.concat(emails.data),
                                hasMore: emails.hasMore,
                                page: emails.page
                            },
                            isSearch: true,
                            firstApiCallAlreadyMade: true
                        }));
                    }
                }) : 
            getFileEmails(fileId, page, dispatch).then(emails => {
                if(Array.isArray(emails?.data)){
                    setState(prev => ({
                        ...prev,
                        data: {
                            ...prev.data,
                            items: prev.data.items.concat(emails.data),
                            hasMore: emails.hasMore,
                            page: emails.page
                        },
                        isSearch: false,
                        firstApiCallAlreadyMade: true
                    }));
                }
            });
    }

    const approve = (fileId, emailId, approved)=> {
        approveSubscription(fileId, emailId, {approved}, dispatch)
            .then(result => {
                if(!result){
                    setState(prev => ({
                        ...prev,
                        data: {
                            ...prev.data,
                            items: prev.data.items.map(item => item.fileId === fileId && item.emailId === emailId ? 
                                {
                                    ...item,
                                    approved
                                } : item
                            )
                        },
                        searchData: {
                            ...prev.searchData,
                            items: prev.searchData.items.map(item => item.fileId === fileId && item.emailId === emailId ? 
                                {
                                    ...item,
                                    approved
                                } : item
                            )
                        }
                    }))
                }
            })
    }

    return { ...fileData, data, hasMore, search, isSearch, updating, page, approve, loadMore, setSearch, setState, dispatch, searchHandler, resetMode }
}