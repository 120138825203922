export const validators = {
    email: {
        error: "email is not valid",
        regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    },
    password: [
        {
          error: "password must contain at least one lowercase letter",
          regex: /^(?=.*[a-z])/
        },
        {
          error: "password must contain at least one uppercase letter",
          regex: /^(?=.*[A-Z])/
        },
        {
          error: "password must contain at least one digit",
          regex: /^(?=.*\d)/
        },
        {
          error: "password must contain at least one special character",
          regex: /^(?=.*[^\da-zA-Z])/
        },
        {
          error: "password must be at least 8 characters long",
          regex: /^.{8,}$/
        }
    ],
    name: {
      error: "name is not valid",
      regex: /^(?:[a-zA-Z1-9]{1,50}|(?=.{1,51}$)[a-zA-Z1-9]+ [a-zA-Z1-9]+)$/
    },
    gsm: {
      error: "invalid gsm",
      regex: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/
    },
    zoho_list_key: {
      error: "invalid list key",
      regex: /^[a-zA-Z0-9]{20,100}$/
    },
    zoho_client_id: {
      error: "invalid client id",
      regex: /^(?=.{5,255}$)[0-9]+\.[a-zA-Z0-9]+$/
    },
    zoho_client_secret: {
      error: "invalid client secret",
      regex: /^[a-zA-Z0-9]{10,50}$/
    },
    zoho_refresh_token: {
      error: "invalid refresh token",
      regex: /^(?=.{5,255}$)[0-9]+\.[a-zA-Z0-9]+\.[a-zA-Z0-9]+$/
    }
};


export function validateKeyValue(key, value){
    if(!(key in validators)){
      return null;
    }
    
    const validator = validators[key];
    
    let error = null;

    if(Array.isArray(validator)){
      error = validator.find(item => !item.regex.test(value))?.error || null;
    }else if(!validator.regex.test(value)){
      error = validator.error
    }

    return error || null; 
}