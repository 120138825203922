import Modal from "../../../components/Modal";
import useZoho from "../../../hooks/useZoho";
import styles from "./zoho.module.css";

export default function ZohoPage(){
    const { state, loading, submit_label, changeHandler, submit, goBack } = useZoho();

    return(
        <Modal onClick={goBack}>
            <form 
                onClick={(ev)=> ev.stopPropagation && ev.stopPropagation()} 
                className={`flex flex-col custom-container ${styles["container"]}`}
            >
                <div className={`flex flex-col ${styles["header"]}`}>
                    <h3 className={styles.title}>setup zoho mail</h3>
                </div>
                <label className={styles["label"]}>
                    <span>client id</span>
                    <input 
                        id="client_id"
                        type="text" 
                        name="client_id" 
                        className={styles["input"]}
                        value={state.data?.client_id || ""}
                        placeholder="1000.XXXXXXXXX....."
                        onChange={(ev)=> changeHandler(ev.target.id, ev.target.value)}
                    />
                    <span className={styles["error"]}>{state.errors.client_id || ""}</span>
                </label>
                <label className={styles["label"]}>
                    <span>client secret</span>
                    <input 
                        id="client_secret"
                        type="text" 
                        name="client_secret" 
                        className={styles["input"]}
                        value={state.data?.client_secret || ""}
                        placeholder="2adzadjzd......."
                        onChange={(ev)=> changeHandler(ev.target.id, ev.target.value)}
                    />
                    <span className={styles["error"]}>{state.errors.client_secret || ""}</span>
                </label>
                <label className={styles["label"]}>
                    <span>refresh token</span>
                    <input 
                        id="refresh_token"
                        type="text" 
                        name="refresh_token" 
                        className={styles["input"]}
                        value={state.data?.refresh_token || ""}
                        placeholder="1000.764XXXXXX"
                        onChange={(ev)=> changeHandler(ev.target.id, ev.target.value)}
                    />
                    <span className={styles["error"]}>{state.errors.refresh_token || ""}</span>
                </label>
                <label className={styles["label"]}>
                    <span>list key</span>
                    <input 
                        id="list_key"
                        type="text" 
                        name="list_key" 
                        className={styles["input"]}
                        value={state.data?.list_key || ""}
                        placeholder="2adzadjzd......."
                        onChange={(ev)=> changeHandler(ev.target.id, ev.target.value)}
                    />
                    <span className={styles["error"]}>{state.errors.list_key || ""}</span>
                </label>
                <span className={styles["error"]}>{state.errors.global || ""}</span>
                <div className={`flex flex-row items-center justify-end ${styles["buttons"]}`}>
                    <button 
                        type="button"
                        value="cancel"
                        name="cancel"
                        className={`${styles["button"]} ${styles["cancel-button"]}`}
                        disabled={loading}
                        onClick={goBack}
                    >
                        cancel
                    </button>
                    <button 
                        type="submit"
                        value="delete"
                        className={`${styles["button"]} ${styles["submit-button"]}`}
                        disabled={loading}
                        onClick={submit}
                    >
                        {submit_label}
                    </button>
                </div>
            </form>
        </Modal>
    );
}