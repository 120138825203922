import Switch from '@mui/material/Switch';
import styles from "./email.module.css";
import { abbreviateNumber } from 'js-abbreviation-number';
import { useState } from 'react';
import { validateKeyValue } from '../../../helpers/validators';
import DonutLoader from '../../Loader/donut';

export default function EmailsItem({
    item, 
    approving = false, 
    selected = {
        value: undefined,
        onChange: undefined
    }, 
    editable = {
        value: false,
        onSave: async (emailId, data)=> emailId && data
    },
    onChange
}){
    const [state, setState] = useState({
        item: {
            ...item,
        },
        errors: {
            email: "",
            gsm_company: "",
            company: "",
            name: "",
            gsm: "",
            address: ""
        },
        editing: false
    });

    if(typeof item !== "object") return null;

    const { id, email, company, gsm_company, name, gsm, address, approved, verified, downloadsNumber } = editable && state.editing ? state.item : item;
    const disableSwitch = typeof onChange !== "function";

    const approve = ()=> {
        if(approving || typeof onChange !== 'function') return;

        onChange(!approved);
    }

    const select = ()=> {
        if(approving || typeof selected.onChange !== 'function') return;
        
        selected.onChange(!!!selected.value);
    }

    const emailChangeHandler = (value)=> {
        if(approving || !editable.value || !state.editing) return;

        const validation = validateKeyValue("email", value);

        setState(prev => ({
            ...prev,
            item: {
                ...prev.item,
                email: value
            },
            errors: {
                ...prev.errors,
                email: validation
            }
        }));
    }

    const inputChangeHandler = (key, value)=> {
        if(approving || !editable.value || !state.editing) return;

        const validation = typeof value === 'string' && value.length > 0 ? "The maximum length for this field is 255 characters" : "";

        setState(prev => ({
            ...prev,
            item: {
                ...prev.item,
                [key]: value
            },
            errors: {
                ...prev.errors,
                [key]: validation
            }
        }))
    }

    const doubleClickHandler = ()=> {
        if(approving || !editable.value || state.editing) return;

        setState(prev => ({...prev, editing: !prev.editing}));
    }

    const reset = ()=> {
        if(approving || !editable.value || !state.editing) return;

        setState(prev => ({
            ...prev,
            editing: false,
            item: {
                ...item
            }
        }));
    }

    const saveChanges = ()=> {
        if(approving || !editable.value || !state.editing) return;

        if(!validateKeyValue("email", state.item.value)){
            setState(prev => ({
                ...prev,
                errors: {
                    ...prev.errors,
                    email: "invalid email"
                }
            }));
            return;
        }

        typeof editable.onSave === "function" && editable
            .onSave(id, state.item)
            .then(result => {
                if(result){
                    reset();
                }
            });
    }

    return(
        <tr key={id} className={styles["email"]}>
            {
                typeof selected.value === "boolean" &&
                <td>
                    {
                        approving ? 
                        <DonutLoader /> :
                        (
                            editable.value && state.editing ?
                            <div className={`flex flex-row ${styles["buttons"]}`}>
                                <button 
                                    onClick={saveChanges}
                                    className={`${styles["button"]} ${styles["save-button"]}`}
                                    disabled={approving}
                                >
                                    save
                                </button>
                                <button 
                                    onClick={reset}
                                    className={`${styles["button"]} ${styles["reset-button"]}`}
                                    disabled={approving}
                                >
                                    reset
                                </button>
                            </div> : 
                            <input 
                                type='checkbox' 
                                name='company-row' 
                                onChange={select} 
                                checked={selected.value}
                                disabled={approving}
                            />
                        )
                    }
                </td>
            }
            <td>
                {
                    editable && state.editing ?  
                    <div className={`flex flex-col`}>
                        <input 
                            type='email' 
                            className={styles["input"]} 
                            placeholder='email' 
                            value={email} 
                            name='email' 
                            onChange={(ev)=> emailChangeHandler(ev.target.value)}
                            disabled={approving}
                        />
                        <span className={styles["error"]}>{state.errors.email}</span>
                    </div> : 
                    <div className={`flex flex-row items-center justify-center`}>
                        <span onDoubleClick={doubleClickHandler}>{email}</span>
                    </div>
                }
            </td>
            <td>
                {
                    editable && state.editing ?
                    <input 
                        type='text' 
                        className={styles["input"]} 
                        placeholder='comapany name' 
                        value={company} 
                        name='company' 
                        onChange={(ev)=> inputChangeHandler("company", ev.target.value)}
                        disabled={approving}
                    /> : 
                    <div className={`flex flex-row items-center justify-center`}>
                        <span onDoubleClick={doubleClickHandler}>{company || "-"}</span>
                    </div>
                }
            </td>
            <td>
                {
                    editable && state.editing ?
                    <input 
                        type='tel' 
                        className={styles["input"]} 
                        placeholder='comapany gsm' 
                        value={gsm_company} 
                        name='gsm_company' 
                        onChange={(ev)=> inputChangeHandler("gsm_company", ev.target.value)}
                        disabled={approving}
                    /> : 
                    <div className={`flex flex-row items-center justify-center`}>
                        <span onDoubleClick={doubleClickHandler}>{gsm_company || "-"}</span>
                    </div>
                }
            </td>
            <td>
                {
                    editable && state.editing ?
                    <input 
                        type='text' 
                        className={styles["input"]} 
                        placeholder='responsible name' 
                        value={name} 
                        name='name' 
                        onChange={(ev)=> inputChangeHandler("name", ev.target.value)}
                        disabled={approving}
                    /> : 
                    <div className={`flex flex-row items-center justify-center`}>
                        <span onDoubleClick={doubleClickHandler}>{name || "-"}</span>
                    </div>
                }
            </td>
            <td>
                {
                    editable && state.editing ?
                    <input 
                        type='tel' 
                        className={styles["input"]} 
                        placeholder='responsible gsm' 
                        value={gsm} 
                        name='gsm' 
                        onChange={(ev)=> inputChangeHandler("gsm", ev.target.value)}
                        disabled={approving}
                    /> : 
                    <div className={`flex flex-row items-center justify-center`}>
                        <span onDoubleClick={doubleClickHandler}>{gsm || "-"}</span>
                    </div>
                }
            </td>
            <td>
                {
                    editable && state.editing ?
                    <input 
                        type='text' 
                        className={styles["input"]} 
                        placeholder='company address' 
                        value={address} 
                        name='address' 
                        onChange={(ev)=> inputChangeHandler("address", ev.target.value)}
                        disabled={approving}
                    /> : 
                    <div className={`flex flex-row items-center justify-center`}>
                        <span onDoubleClick={doubleClickHandler}>{address || "-"}</span>
                    </div>
                }
            </td>
            {
                typeof downloadsNumber === "number" &&
                <td>{abbreviateNumber(downloadsNumber,  2, { padding: false })}</td>
            }
            {
                typeof approved === "boolean" && 
                <td>
                    <Switch 
                        onChange={approve}
                        checked={approving ? !!!approved : !!approved}
                        disabled={approving || disableSwitch}
                    />
                </td>
            }
            {
                typeof verified === "boolean" && 
                <td>
                    <Switch 
                        checked={verified}
                        disabled={true}
                    />
                </td>
            }
        </tr>
    );
}