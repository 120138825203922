import { EMAILS_INITIAL_DATA } from "../emails";

export const emailsActions = {
    EMAIL_CREATED: "EMAIL_CREATED",
    UPDATING_EMAIL: "UPDATING_EMAIL",
    APPROVING_EMAILS: "APPROVING_EMAILS",
    EMAILS_APPROVED: "EMAILS_APPROVED",
    EMAIL_APPROVE_FAILED: "EMAIL_APPROVE_FAILED",
    EMAIL_APPROVED: "EMAIL_APPROVED",
    EMAIL_UPDATED: "EMAIL_UPDATED",
    EMAIL_UPDATE_FAIL: "EMAIL_UPDATE_FAIL",
    EMAILS_RETRIEVED: "EMAILS_RETRIEVED",
    SEARCH_EMAILS_RETRIEVED: "SEARCH_EMAILS_RETRIEVED",
    CHECK_EMAIL: "CHECK_EMAIL",
    CHECK_SEARCH_EMAIL: "CHECK_SEARCH_EMAIL",
    CHECK_ALL_EMAILS: "CHECK_ALL_EMAILS",
    CHECK_ALL_SEARCH_EMAILS: "CHECK_ALL_SEARCH_EMAILS",
    EMAILS_RETRIEVED_FAIL: "EMAILS_RETRIEVED_FAIL",
    SEARCH_EMAILS_RETRIEVED_FAIL: "SEARCH_EMAILS_RETRIEVED_FAIL",
    SEARCH_FILES_RETRIEVED_FAIL: "SEARCH_FILES_RETRIEVED_FAIL",
    RESET_EMAILS_MODE: "RESET_EMAILS_MODE",
    EMAILS_DELETED: "EMAILS_DELETED",
    EMAILS_DELETEION_FAIL: "EMAILS_DELETEION_FAIL",
    LOADING: "LOADING",
    LOAD_ENDED: "LOAD_ENDED",
    LOGOUT: "LOGOUT"
};

export function emailsReducer(state, action){
    if(typeof state !== "object"){
      state = {
        ...EMAILS_INITIAL_DATA
      };
    }
  
    switch(action.type){
        case emailsActions.EMAIL_CREATED: {
            if(!action.payload){
              return({
                ...state,
                loading: false
              });
            }
      
            let emails = [];
      
            if(Array.isArray(action.payload)){
              emails = action.payload;
            }else{
              emails.push(action.payload);
            }

            return({
                ...state,
                data: {
                    ...state.data,
                    items: [
                        ...emails,
                        ...state.data.items
                    ]
                },
                loading: false
            });
        }
        case emailsActions.UPDATING_EMAIL: {
            if(!action.payload?.id){
                return({
                    ...state,
                    loading: false
                });
            }
      
            const id = action.payload.id;
      
            return({
                ...state,
                updating: new Map(state.updating.set(id, true))
            });
        }
        case emailsActions.EMAIL_UPDATE_FAIL: {
            if(!action.payload?.id){
              return({
                    ...state,
                    loading: false
              });
            }
      
            const id = action.payload.id;
      
            return({
                ...state,
                updating: new Map(state.updating.set(id, false))
            });
        }
        case emailsActions.EMAIL_APPROVED: {
            if(!action.payload?.id){
                return({
                    ...state,
                    loading: false
                });
            }
      
            const id = action.payload.id;
      
            return({
                ...state,
                data: {
                    ...state.data,
                    items: state.data.items.map(item => item.id === id ? {...item, approved: !!!item.approved } : item),
                },
                searchData: {
                    ...state.searchData,
                    items: state.searchData.items.map(item => item.id === id ? {...item, approved: !!!item.approved} : item),
                },
                updating: new Map(state.updating.set(id, false)),
                loading: false
            });
        }
        case emailsActions.EMAIL_UPDATED: {
            if(!action.payload?.id || typeof action.payload.email !== "object"){
                return({
                    ...state,
                    loading: false
                });
            }
      
            const id = action.payload.id;
      
            return({
                ...state,
                data: {
                    ...state.data,
                    items: state.data.items.map(item => item.id === id ? action.payload.email : item),
                },
                searchData: {
                    ...state.searchData,
                    items: state.searchData.items.map(item => item.id === id ? action.payload.email : item),
                },
                updating: new Map(state.updating.set(id, false)),
                loading: false
            });
        }
        case emailsActions.EMAILS_RETRIEVED: {
            return({
                ...state,
                data: {
                    ...state.data,
                    items: action.payload?.page === 2 ? action.payload?.data || [] : [...state?.data.items || [], ...action.payload?.data || []],
                    hasMore: !!action.payload?.hasMore,
                    page: action.payload?.page || 1,
                    selected: action.payload?.page === 2 ? new Map() : state.data.selected,
                    selectAll: false,
                    firstApiCallAlreadyMade: true,
                },
                loading: false,
            });
        }
        case emailsActions.APPROVING_EMAILS: {
            if(!Array.isArray(action.payload?.ids)) return {
                ...state,
                loading: false
            };

            const ids = action.payload.ids;

            ids.forEach(id => {
                state.updating.set(id, true);
            });

            return({
                ...state,
                updating: new Map(state.updating),
                loading: true
            });
        }
        case emailsActions.EMAILS_APPROVED: {
            if(!Array.isArray(action.payload.ids)) return {
                ...state,
                loading: false
            };

            const ids = action.payload.ids;
            ids.forEach(id => {
                state.updating.set(id, false);
            });
      
            return({
                ...state,
                data: {
                    ...state.data,
                    items: state.data.items.map(item => ids.includes(item.id) ? {...item, approved: !!!item.approved } : item),
                },
                searchData: {
                    ...state.searchData,
                    items: state.searchData.items.map(item => ids.includes(item.id) ? {...item, approved: !!!item.approved} : item),
                },
                updating: new Map(state.updating),
                loading: false
            });
        }
        case emailsActions.EMAIL_APPROVE_FAILED: {
            if(!Array.isArray(action.payload.ids)) return {
                ...state,
                loading: false
            };

            const ids = action.payload.ids;
            ids.forEach(id => {
                state.updating.set(id, false);
            });
      
            return({
                ...state,
                updating: new Map(state.updating),
                loading: false
            });
        }
        case emailsActions.SEARCH_EMAILS_RETRIEVED: {
            return({
                ...state,
                searchData: {
                    ...state.searchData,
                    items: action.payload?.page === 2 ? action.payload?.data || [] : [...state?.searchData.items || [], ...action.payload?.data || []],
                    hasMore: !!action.payload?.hasMore,
                    page: action.payload?.page || 1,
                    selected: action.payload?.page === 2 ? new Map() : state.searchData.selected,
                    selectAll: false,
                    firstApiCallAlreadyMade: true
                },
                isSearch: true,
                loading: false,
            });
        }
        case emailsActions.EMAILS_RETRIEVED_FAIL: {
            return({
                ...state,
                data: {
                    ...state.data,
                    firstApiCallAlreadyMade: true,
                },
                loading: false
            });
        }
        case emailsActions.SEARCH_EMAILS_RETRIEVED_FAIL: {
            return({
                ...state,
                searchData: {
                    ...state.searchData,
                    firstApiCallAlreadyMade: true,
                },
                loading: false
            });
        }
        case emailsActions.RESET_EMAILS_MODE: {
            return({
                ...state,
                searchData: {
                    items: [],
                    hasMore: true,
                    page: 1,
                    firstApiCallAlreadyMade: false,
                    selected: new Map(),
                    selectAll: false
                },
                isSearch: false
            })
        }
        case emailsActions.LOADING: {
            return({
                ...state,
                loading: true
            });
        }
        case emailsActions.LOAD_ENDED: {
            return({
                ...state,
                loading: false
            });
        }
        case emailsActions.CHECK_EMAIL: {
            if(!action.payload?.id){
                return({
                    ...state,
                });
            }

            const selected = state.data.selected.set(action.payload.id, !!action.payload.value);

            if(!action.payload.value){
                selected.delete(action.payload.id);
            }

            return({
                ...state,
                data: {
                    ...state.data,
                    selected: new Map(selected),
                    selectAll: false
                }
            });
        }
        case emailsActions.CHECK_SEARCH_EMAIL: {
            if(!action.payload?.id){
              return({
                ...state,
              });
            }

            const selected = state.searchData.selected.set(action.payload.id, !!action.payload.value);

            if(!action.payload.value){
                selected.delete(action.payload.id);
            }

            return({
                ...state,
                searchData: {
                    ...state.searchData,
                    selected: new Map(selected),
                    selectAll: false
                }
            });
        }
        case emailsActions.CHECK_ALL_EMAILS: {
            return({
                ...state,
                data: {
                    ...state.data,
                    selectAll: !state.data.selectAll,
                    selected: state.data.selectAll ? new Map() : state.data.items.reduce((map, item)=> {
                        map.set(item.id, !state.data.selectAll);
                        return map;
                    }, new Map())
                }
            });
        }
        case emailsActions.CHECK_ALL_SEARCH_EMAILS: {
            return({
                ...state,
                searchData: {
                    data: {
                        ...state.searchData,
                        selectAll: !state.searchData.selectAll,
                        selected: state.searchData.selectAll ? new Map() : state.searchData.items.reduce((map, item)=> {
                            map.set(item.id, !state.searchData.selectAll);
                            return map;
                        }, new Map())
                    }
                }
            });
        }
        case emailsActions.EMAILS_DELETED: {
            const ids = action.payload.ids || [];

            return({
                ...state,
                data: {
                    ...state.data,
                    items: state.data.items.filter(item => ids.includes(item.id))
                },
                searchData: {
                    ...state.searchData,
                    items: state.searchData.items.filter(item => ids.includes(item.id))
                },
                loading: false
            })
        }
        case emailsActions.EMAILS_DELETEION_FAIL: {
            return({
                ...state,
                loading: false
            })
        }
        case emailsActions.LOGOUT: {
            return({
                ...EMAILS_INITIAL_DATA
            });
        }
      default: break;
    }
  
    return state;
}