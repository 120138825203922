import axios from "axios";

export default function AxiosErrorHandler(error){
    let result = {
        message: error?.message || "an error occured"
    }

    if(axios.isAxiosError(error) && error.response){
        result = error.response.data;
    }

    // notify.error(result.message);
    return result;
}