import Modal from "../../../../components/Modal";
import useImportEmailsData from "../../../../hooks/useImportEmailsData";
import styles from "./import.module.css";

export default function ImportEmailsPage(){
    const { state, loading, fileName, invalidRecords, realTotalRecords, totalRecords, goBack, fileChangeHandler, submit } = useImportEmailsData();

    return(
        <Modal 
            className={styles["modal"]} 
            onClick={goBack} 
        >
            <label 
                onClick={(ev)=> {
                    ev.stopPropagation && ev.stopPropagation();
                    
                    return(!state.file);
                }} 
                className={`flex flex-col custom-container ${styles["container"]}`}
                onDrop={(event) => {
                    event.preventDefault();
                    fileChangeHandler(event);
                }}
                onDragOver={ev => ev.preventDefault()}
            >                
                <input
                    type="file"
                    hidden
                    accept=".csv,.xlsx" 
                    disabled={state.file}               
                    onChange={fileChangeHandler}
                />
                {
                    state.file && 
                    <div className={`flex flex-col ${styles["header"]}`}>
                        <h3 className={styles["title"]}>import emails:</h3>
                        <h4 className={styles["file-name"]}>filename: {fileName}</h4>
                        <h4 className={styles["file-name"]}>total records: {realTotalRecords}</h4>
                        {
                            invalidRecords > 0 && 
                            <h4 className={styles["file-name"]}>invalid records: {invalidRecords} / {realTotalRecords}</h4>
                        }
                        <h4 className={styles["file-name"]}>created records: {totalRecords} / {realTotalRecords}</h4>
                    </div>
                }
                <div className={`flex flex-col ${styles["emails-wrapper"]}`}>
                    {
                        Array.isArray(state.data) && state.data.length > 0 && 
                        <div className={`flex flex-row items-start ${styles["emails-container"]}`}>
                            <table className={`custom-container ${styles["emails"]}`}>
                                <thead>
                                    <tr className={`${styles["email"]} ${styles["head"]}`}>
                                        <th>index</th>
                                        <th>email</th>
                                        <th>company</th>
                                        <th>company gsm</th>
                                        <th>name</th>
                                        <th>gsm</th>
                                        <th>address</th>
                                    </tr>
                                </thead>
                                <tbody> 
                                    {
                                        state.data.map((item, id) => {
                                            if(typeof item !== "object") return null;

                                            const { email, company, gsm_company, name, gsm, address } = item;
                                            let invalidFields =  item.invalidFields || [];
                                            
                                            if(!Array.isArray(item.invalidFields)){
                                                invalidFields = [];
                                            }

                                            return(
                                                <tr key={id} className={styles["email"]}>
                                                    <td data-invalid={invalidFields.length > 0}>{id + 1}</td>
                                                    <td data-invalid={invalidFields.includes("email")}>{email || "-"}</td>
                                                    <td data-invalid={invalidFields.includes("company")}>{company || "-"}</td>
                                                    <td data-invalid={invalidFields.includes("gsm_company")}>{gsm_company || "-"}</td>
                                                    <td data-invalid={invalidFields.includes("name")}>{name || "-"}</td>
                                                    <td data-invalid={invalidFields.includes("gsm")}>{gsm || "-"}</td>
                                                    <td>{address || "-"}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
                <div className={`flex flex-row items-center justify-end ${styles["buttons"]}`}>
                    <button 
                        type="button"
                        value="cancel"
                        name="cancel"
                        className={`${styles["button"]} ${styles["cancel-button"]}`}
                        disabled={loading}
                        onClick={goBack}
                    >
                        close
                    </button>
                    {
                        state.data.length > 0 && 
                        <button 
                            type="button"
                            value="cancel"
                            name="cancel"
                            className={`${styles["button"]} ${styles["import-button"]}`}
                            disabled={loading}
                            onClick={submit}
                        >
                            import
                        </button>
                    }
                </div>
            </label>
        </Modal>
    )
}