import { useParams } from "react-router-dom";
import useGoBack from "./useGoBack";
import { useContext, useEffect } from "react";
import { FilesContext, FilesDispatchContext } from "../context/files";
import useLoading from "./useLoading";
import { UserContext } from "../context";

export default function useFile(){
    const { fileId } = useParams();

    const dispatch = useContext(FilesDispatchContext);
    const { goBack, navigate } = useGoBack();

    const loading = useLoading();
    const files = useContext(FilesContext);
    const { user } = useContext(UserContext);

    const file = ((files.isSearch ? files.searchData.items : files.data.items) || []).find(file => file.id === fileId);
    const userId = user?.id;

    useEffect(()=> {
        if(!file || !userId){
            goBack();
        }
    }, [file, userId, goBack]);

    const title = file?.originalName || file?.name || "";

    return { file, user, userId, fileId, title, loading, dispatch, navigate, goBack }
}