import { FILES_INITIAL_DATA } from "../files";

export const filesActions = {
    FILE_CREATED: "FILE_CREATED",
    FILE_DELETED: "FILE_DELETED",
    FILE_UPDATED: "FILE_UPDATED",
    FILES_RETRIEVED: "FILES_RETRIEVED",
    SEARCH_FILES_RETRIEVED: "SEARCH_FILES_RETRIEVED",
    FILES_RETRIEVED_FAIL: "FILES_RETRIEVED_FAIL",
    RESET_FILES_MODE: "RESET_FILES_MODE",
    LOADING: "LOADING",
    LOAD_ENDED: "LOAD_ENDED",
    LOGOUT: "LOGOUT"
}

export function filesReducer(state, action){
    if(typeof state !== "object"){
      state = {
        ...FILES_INITIAL_DATA
      };
    }
  
    switch(action.type){
      case filesActions.FILE_CREATED: {
        if(!action.payload){
          return({
            ...state,
            loading: false
          });
        }
  
        let files = [];
  
        if(Array.isArray(action.payload)){
          files = action.payload;
        }else{
          files.push(action.payload);
        }
        return({
          ...state,
          data: {
            ...state.data,
            items: [
              ...files,
              ...state.data.items
            ]
          },
          loading: false
        });
      }
      case filesActions.FILE_DELETED: {
        if(!Array.isArray(state?.data.items)){
          return({
            ...state,
            loading: false
          });
        }
  
        return({
          ...state,
          data: {
            ...state.data,
            items: state.data.items.filter(item => item.id !== action.payload.id)
          },
          searchData: {
            ...state.searchData,
            items: state.searchData.items.filter(item => item.id !== action.payload.id)
          },
          loading: false
        });      
      }
      case filesActions.FILE_UPDATED: {
        if(!action.payload){
          return({
            ...state,
            loading: false
          });
        }
  
        return({
          ...state,
          data: {
            ...state.data,
            items: state.data.items.map(item => item.id === action.payload.id ? action.payload : item),
          },
          loading: false
        });      
      }
      case filesActions.FILES_RETRIEVED: {
        return({
          ...state,
          data: {
            items: action.payload?.page === 2 ? action.payload?.data || [] : [...state?.data.items || [], ...action.payload?.data || []],
            hasMore: !!action.payload?.hasMore,
            page: action.payload?.page || 1,
            firstApiCallAlreadyMade: true
          },
          loading: false,
        });
      }
      case filesActions.SEARCH_FILES_RETRIEVED: {
        return({
          ...state,
          searchData: {
            items: action.payload?.page === 2 ? action.payload?.data || [] : [...state?.searchData.items || [], ...action.payload?.data || []],
            hasMore: !!action.payload?.hasMore,
            page: action.payload?.page || 1,
            firstApiCallAlreadyMade: true
          },
          isSearch: true,
          loading: false,
        });
      }
      case filesActions.FILES_RETRIEVED_FAIL: {
        return({
          ...state,
          data: {
            ...state.data,
            firstApiCallAlreadyMade: true,
          },
          loading: false
        });
      }
      case filesActions.RESET_FILES_MODE: {
        return({
          ...state,
          searchData: {
            items: [],
            hasMore: true,
            page: 1,
            firstApiCallAlreadyMade: false,
          },
          isSearch: false
        })
      }
      case filesActions.LOADING: {
        return({
          ...state,
          loading: true
        });
      }
      case filesActions.LOAD_ENDED: {
        return({
          ...state,
          loading: false
        });
      }
      case filesActions.LOGOUT: {
        return({
          ...FILES_INITIAL_DATA
        });
      }
      default: break;
    }
  
    return state;
}