import { useState } from "react";
import Modal from "../../../components/Modal";
import useFile from "../../../hooks/useFile";
import styles from "./update.module.css";
import { validateKeyValue } from "../../../helpers/validators";
import { updateFile } from "../../../api";
import { fileType } from "../create";
import { bytesToMB } from "../../../helpers";

export default function UpdatePage(){
    const fileData = useFile();
    const { file, loading, fileId, title, dispatch } = fileData;
    let { goBack } = fileData;

    const [state, setState] = useState({
        file: null,
        password: file.password || "",
        errors: {
            file: "",
            password: "",
            global: ""
        },
        fileSize: 0,
        filesDialogOpen: false,
        showPassword: false
    });

    if(!file) return null;

    goBack = loading || state.filesDialogOpen ? ()=> false : goBack;

    const handleSubmit = (event)=> {
        event?.preventDefault();

        if(loading || state.filesDialogOpen) return;

        const { file: newFile, password } = state;

        const formData = new FormData();
        let fileValidation = "";

        if(newFile){
            if(state.fileSize > 100){
                fileValidation = "file size can not exceed 100mb";
            }else{
                formData.append("files", newFile);
            }
        }

        if(password !== file.password){
            const passwordValidation = validateKeyValue("password", password);

            if(!passwordValidation){
                formData.append("password", password);
            }

            setState(prev => ({
                ...prev,
                errors: {
                    ...prev.errors,
                    file: fileValidation,
                    password: passwordValidation,
                }
            }));
        }

        if(!formData.get("files") && !formData.get("password")) return;

        updateFile(fileId, formData, dispatch)
            .then(result => {
                if(!result){
                    return goBack();
                }

                setState(prev => ({
                    ...prev,
                    errors: {
                        ...prev.errors,
                        global: result.message
                    }
                }));
            });
    }

    const passwordChangeHandler = (ev)=> {
        setState(prev => ({
            ...prev, 
            password: ev.target.value, 
            errors: {
                ...prev.errors,
                password: validateKeyValue("password", ev.target.value)
            }
        }));
    }

    const fileChangeHandler = (ev)=> {
        const file = ev.target.files[0];
        const size = file ? bytesToMB(file.size) : 0;
        const validation = size > 100 ? "file size can not exceed 100mb": "";

        setState(prev => ({
            ...prev, 
            file: ev.target.files[0], 
            filesDialogOpen: false,
            errors: {
                ...prev.errors,
                file: validation
            },
            fileSize: size
        }));
    }

    return(
        <Modal onClick={goBack}>
            <form  onClick={(ev)=> ev.stopPropagation && ev.stopPropagation()}  onSubmit={handleSubmit} className={`custom-container flex flex-col ${styles["container"]}`}>
                <div className={`flex flex-col ${styles["header"]}`}>
                    <h3 className={styles.title}>update file:</h3>
                    <div className={`flex flex-col items-center justify-center ${styles["content"]}`}>
                        <h2 className={styles["file-title"]} title={title}>{title}</h2>
                    </div>
                </div>
                <label className={styles["label"]}>
                    <span>file</span>
                    <input 
                        id="file"
                        type="file" 
                        name="files" 
                        accept={fileType}
                        className={styles["input"]}
                        onChange={fileChangeHandler}
                        onFocus={()=> setState(prev => ({
                            ...prev,
                            filesDialogOpen: true
                        }))}
                        onBlur={()=> setState(prev => ({
                            ...prev,
                            filesDialogOpen: false
                        }))}
                    />
                    <span className={styles["error"]}>{state.errors.file || ""}</span>
                </label>
                <label className={styles["label"]}>
                    <div className={`flex flex-row justify-between items-center gap-[5px]`}>
                        <span>password</span>
                        <span 
                            onClick={()=> setState(prev => ({
                                ...prev,
                                showPassword: !prev.showPassword
                            }))} 
                            data-show={state.showPassword} 
                            className={styles["show-hide"]}
                        >
                            {state.showPassword ? "hide" : "show"}
                        </span>
                    </div>
                    <input 
                        id="password"
                        type={state.showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Pass@Word123"
                        className={styles["input"]}
                        value={state.password}
                        onChange={passwordChangeHandler}
                    />
                    <span className={styles["error"]}>{state.errors.password || ""}</span>
                </label>
                <span className={styles["error"]}>{state.errors.global || ""}</span>
                <div className={`flex flex-row items-center justify-end ${styles["buttons"]}`}>
                    <button 
                        type="button"
                        value="cancel"
                        name="cancel"
                        className={`${styles["button"]} ${styles["cancel-button"]}`}
                        disabled={loading}
                        onClick={goBack}
                    >
                        cancel
                    </button>
                    <button 
                        type="submit"
                        value="delete"
                        className={`${styles["button"]} ${styles["submit-button"]}`}
                        disabled={loading}
                    >
                        update
                    </button>
                </div>
            </form>
        </Modal>
    );
}