import { memo } from "react";
import EmailsItem from "../ListItem/emails";

const EmailsList = memo(({ items, selectHandler, selected, updating, approve, editable = {
    value: false,
    onSave: async (emailId, data)=> emailId && data
} })=> {
    return (
        <tbody>
            {Array.isArray(items) && items.map((item) => (
                <EmailsItem
                    key={item.id}
                    item={item}
                    editable={editable}
                    selected={{
                        value: selected.has(item.id) && selected.get(item.id),
                        onChange: (value) => selectHandler(item.id, value)
                    }}
                    approving={updating.has(item.id) && updating.get(item.id)}
                    onChange={(approved) => approve(approved, item.id)}
                />
            ))}
        </tbody>
    );
}, (prevProps, nextProps)=> {
    return prevProps.items === nextProps.items && prevProps.selected === nextProps.selected && prevProps.updating === nextProps.updating;
});

export default EmailsList;