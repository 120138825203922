import { createContext } from "react";
import { emailsReducer } from "./reducers/emails";

const INITIAL_STATE = {
    data: {
        items: [],
        hasMore: true,
        page: 1,
        firstApiCallAlreadyMade: false,
        selected: new Map(),
        selectAll: false
    },
    searchData: {
        items: [],
        hasMore: true,
        page: 1,
        firstApiCallAlreadyMade: false,
        selected: new Map(),
        selectAll: false
    },
    updating: new Map(),
    isSearch: false,
    loading: false,
};

export const EmailsContext = createContext(INITIAL_STATE);
export const EmailsDispatchContext = createContext(emailsReducer);
export const EMAILS_INITIAL_DATA = INITIAL_STATE;