import { useContext, useState } from "react";
import useGoBack from "./useGoBack";
import { UserContext, UserDispatchContext } from "../context";
import { validateKeyValue } from "../helpers/validators";
import { updateEmailsManager } from "../api";

export default function useZoho(){
    const { goBack } = useGoBack();
    const { user, loading } = useContext(UserContext) || {};
    const dispatch = useContext(UserDispatchContext);
    
    const emails_manager = user.emails_manager || {};
    const userId = user.id;

    const [state, setState] = useState({
        data: emails_manager,
        errors: {

        }
    });

    const changeHandler = (key, value)=> {
        if(typeof key !== "string" || typeof value !== "string") return;

        const isValid = !validateKeyValue(`zoho_${key}`, value);

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [key]: value
            },
            errors: {
                ...prev.errors,
                [key]: isValid ? "" : `invalid ${key.split("_").join(" ")}`
            }
        }))
    }

    const submit = async (ev)=> {
        ev.preventDefault && ev.preventDefault();

        if(!userId) return;

        if(!emails_manager){
            if(validateKeyValue("zoho_client_id", state.data.client_id || "")){
                return setState(prev => ({
                    ...prev,
                    errors: {
                        ...prev.errors,
                        client_id: "invalid client id"
                    }
                }))
            }

            if(validateKeyValue("zoho_client_secret", state.data.client_id || "")){
                return setState(prev => ({
                    ...prev,
                    errors: {
                        ...prev.errors,
                        client_secret: "invalid client secret"
                    }
                }))
            }

            if(validateKeyValue("zoho_list_key", state.data.list_key || "")){
                return setState(prev => ({
                    ...prev,
                    errors: {
                        ...prev.errors,
                        list_key: "invalid list key"
                    }
                }))
            }

            if(validateKeyValue("zoho_refresh_token", state.data.list_key || "")){
                return setState(prev => ({
                    ...prev,
                    errors: {
                        ...prev.errors,
                        refresh_token: "invalid refresh token"
                    }
                }))
            }

            const result = await updateEmailsManager(userId, state.data, dispatch);

            setState(prev => ({
                ...prev, 
                errors: result ? {...prev.errors, global: result.message || "something went wrong"} : {}
            }));

            goBack();
        }

        const updates = {}

        if(state.data.client_id !== emails_manager.client_id) {
            if(validateKeyValue("zoho_client_id", state.data.client_id || "")){
                return setState(prev => ({
                    ...prev,
                    errors: {
                        ...prev.errors,
                        client_id: "invalid client id"
                    }
                }));
            }

            updates.client_id = state.data.client_id;
        }

        if(state.data.client_secret !== emails_manager.client_secret) {
            if(validateKeyValue("zoho_client_secret", state.data.client_secret || "")){
                return setState(prev => ({
                    ...prev,
                    errors: {
                        ...prev.errors,
                        client_secret: "invalid client secret"
                    }
                }));
            }

            updates.client_secret = state.data.client_secret;
        }

        if(state.data.refresh_token !== emails_manager.refresh_token) {
            if(validateKeyValue("zoho_refresh_token", state.data.refresh_token || "")){
                return setState(prev => ({
                    ...prev,
                    errors: {
                        ...prev.errors,
                        refresh_token: "invalid refresh token"
                    }
                }));
            }

            updates.refresh_token = state.data.refresh_token;
        }

        if(state.data.list_key !== emails_manager.list_key) {
            if(validateKeyValue("zoho_list_key", state.data.list_key || "")){
                return setState(prev => ({
                    ...prev,
                    errors: {
                        ...prev.errors,
                        list_key: "invalid list key"
                    }
                }));
            }

            updates.list_key = state.data.list_key;
        }

        if(Object.keys(updates).length === 0) {
            return setState(prev => ({
                ...prev, 
                errors: {...prev.errors, global: "nothing to update"}
            }));
        };

        const result = await updateEmailsManager(userId, updates, dispatch);
 
        setState(prev => ({
            ...prev, 
            errors: result ? {...prev.errors, global: result.message || "something went wrong"} : {}
        }));

        goBack();
    }

    return {
        state,
        loading,
        submit_label: !!emails_manager ? "update" : "create",
        changeHandler,
        goBack,
        submit
    }
}