import { useContext, useEffect, useState } from "react";
import { download, getFiles, getSearchFiles } from "../api";
import { filesActions as actions } from "../context/reducers/files";
import { FilesContext, FilesDispatchContext } from "../context/files";
import { UserContext, UserDispatchContext } from "../context";
import { CopyToClipboard } from "../helpers";
import useLoading from "./useLoading";
import useGoBack from "./useGoBack";
import { userActions } from "../context/reducers";
import { EmailsDispatchContext } from "../context/emails";
import { emailsActions } from "../context/reducers/emails";

export default function useFiles(){
    const [showBadge, setShowBadge] = useState(true);
    const [search, setSearch] = useState({
        value: "",
        prevSearch: null
    });

    const { user, isAuth } = useContext(UserContext) || {};
    const loading = useLoading();
    const files = useContext(FilesContext) || {};
    const dispatch = useContext(FilesDispatchContext);
    const userDispatch = useContext(UserDispatchContext);
    const emailsDispatch = useContext(EmailsDispatchContext);
    const { navigate } = useGoBack();

    const { data: normalData, searchData, isSearch } = files || {};
    const data = (isSearch ? searchData : normalData) || {};
    const { hasMore, page } = data;
    const { firstApiCallAlreadyMade } = normalData || {};
    const items = data.items || [];

    useEffect(()=> {
        if(!isAuth || loading || firstApiCallAlreadyMade || !hasMore) return;

        async function retrieveFiles(){
            await getFiles(1, dispatch);
        }

        retrieveFiles();
        dispatch({
            type: actions.RESET_FILES_MODE
        });
    }, [firstApiCallAlreadyMade, loading, isAuth, hasMore, dispatch]);

    const loadMore = ()=> {
        if(loading || !hasMore) return;

        isSearch ? search.length > 0 && getSearchFiles(page, search.trim(), dispatch) : getFiles(page, dispatch);
    }

    const downloadFile = (fileId, fileName)=> {
        if(loading) return;

        download(fileId, fileName, dispatch);
    }

    const onLinkClick = (ev)=> {
        if(loading) {
            ev?.preventDefault() && ev?.stopPropagation();
            return false;
        }
    }

    const onCopyButtonClick = async (value)=> {
        await CopyToClipboard(value);
    }

    const logout = ()=> {
        localStorage.removeItem("accessToken");
        dispatch({
            type: actions.LOGOUT
        });
        userDispatch({
            type: userActions.LOGOUT
        });
        emailsDispatch({
            type: emailsActions.LOGOUT
        });
        navigate("login");
    }

    const searchHandler = ()=> {
        if(loading) return;

        if(search.value.trim().length === 0) {
            dispatch({
                type: actions.RESET_FILES_MODE
            });
            return;
        };

        if(search.value.trim() === search.prevSearch?.trim()){
            return;
        }
        
        getSearchFiles(1, search.value.trim(), dispatch)
            .then(result => {
                if(!result){
                    setSearch(prev => ({
                        ...prev,
                        prevSearch: search.value.trim()
                    }))
                }
            });
    }

    const resetMode = ()=> {
        if(loading) return;

        dispatch({
            type: actions.RESET_FILES_MODE
        });

        setSearch(prev => ({
            ...prev,
            prevSearch: null
        }));
    }

    const hideBadge = ()=> {
        if(showBadge) setShowBadge(false);
    }

    return({
        search,
        loading,
        files,
        items,
        hasMore,
        page,
        firstApiCallAlreadyMade,
        user,
        isSearch,
        loadMore,
        downloadFile,
        onLinkClick,
        onCopyButtonClick,
        logout,
        searchHandler,
        resetMode,
        setSearch,
        showBadge,
        hideBadge
    });
}