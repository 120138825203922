import { useContext, useState } from "react";
import styles from "./login.module.css";
import { validateKeyValue } from "../../../helpers/validators";
import { UserContext, UserDispatchContext } from "../../../context";
import { login } from "../../../api";

export default function LoginPage(){
    const [state, setState] = useState({
        data: {
            password: "",
            email: ""
        },
        errors: {
            password: null,
            email: null,
            global: null
        }
    });

    const loading = useContext(UserContext).loading;
    const dispatch = useContext(UserDispatchContext);
    
    const onChange = (key, value)=> {
        const validation = validateKeyValue(key, value);

        setState(prev => {
            return({
                ...prev,
                data: {
                    ...prev.data,
                    [key]: value
                },
                errors: {
                    ...prev.errors,
                    [key]: validation
                }
            });
        });
    }

    const onSubmit = (ev)=> {
        ev?.preventDefault();
        if(loading) return;

        if(validateKeyValue("email", state.data.email) === null && validateKeyValue("password", state.data.password) === null){
            login(state.data, dispatch)
                .then(result => {
                    setState(prev => {
                        return({
                            ...prev,
                            errors: {
                                ...prev.globals,
                                global: result?.message || null
                            }
                        });
                    })
                });
        }
    }

    return(
        <div className={`flex flex-col items-center justify-center ${styles["container"]}`}>
            <main id="main" className={`flex flex-col items-center justify-center ${styles["main"]}`}>
                <section className={`flex flex-col items-center justify-center ${styles["info"]}`}>
                    <h1 className={styles["title"]}>Welcome Back!</h1>
                    <h5 className={styles["sub-title"]}>login to your account</h5>
                </section>
                <form onSubmit={onSubmit} className={`flex flex-col items-center justify-center ${styles["form"]}`}>
                    <label className={styles["label"]}>
                        <span>email</span>
                        <input 
                            placeholder="your email" 
                            id="email" 
                            name="email" 
                            value={state.data.email} 
                            type="email" 
                            className={styles["input"]} 
                            disabled={loading}
                            onChange={(ev)=> onChange("email", ev.target.value)} 
                        />
                        <span className={styles["error"]}>{state.errors.email}</span>
                    </label>
                    <label className={styles["label"]}>
                        <span>password</span>
                        <input 
                            placeholder="password" 
                            id="password" 
                            name="password" 
                            value={state.data.password} 
                            type="password" 
                            className={styles["input"]}  
                            disabled={loading}
                            onChange={(ev)=> onChange("password", ev.target.value)} 
                        />
                        <span className={styles["error"]}>{state.errors.password}</span>
                    </label>
                    <button 
                        id="submit" 
                        name="download" 
                        value="download" 
                        type="submit" 
                        className={styles["button"]} 
                        disabled={loading}
                    >
                        login
                    </button>
                    <span className={styles["error"]}>{state.errors.global}</span>
                </form>
                {/* <section className={`flex flex-row items-center justify-center ${styles.footer}`}>
                    <span>do not have an account?</span>
                    <Link to={"/register"}>
                        signup
                    </Link>
                </section> */}
            </main>
        </div>
    );
}