import { abbreviateNumber } from "js-abbreviation-number";
import styles from "./file.module.css";
import { ReactComponent as EditSvg } from "../../../components/svgs/edit.svg";
import { ReactComponent as DeleteSvg } from "../../../components/svgs/trash.svg";
import { ReactComponent as DownloadSvg } from "../../../components/svgs/download.svg";
import { ReactComponent as DownloadsSvg } from "../../../components/svgs/chart-1.svg";
import { ReactComponent as CreatedAtSvg } from "../../../components/svgs/calendar.svg";
import { ReactComponent as UpdatedAtSvg } from "../../../components/svgs/calendar-edit.svg";
import { ReactComponent as CopySvg } from "../../../components/svgs/copy.svg";
import { ReactComponent as EmailSvg } from "../../../components/svgs/mail.svg";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../../config/api";
import { useRef, useState } from "react";

export default function FilesItem({file, download = ()=> false, onLinkClick = ()=> false, onCopyButtonClick = ()=> false}){
    const copyButtonRef = useRef(null);
    const [timeout, setTimeout] = useState(null);

    useState(()=> {
        return ()=> {
            timeout && clearTimeout(timeout);
        };
    }, []);

    if(typeof file !== "object") return null;

    const { id, name, originalName, downloadsCount, emailsCount, allow_all_emails } = file;

    const createdAt = file.createdAt ? new Date(file.createdAt).toLocaleDateString("en-US", { dateStyle: "medium" }) : "-";
    const updatedAt = file.updatedAt ? new Date(file.updatedAt).toLocaleDateString("en-US", { dateStyle: "medium" }) : "-";
    const allowedEmails = allow_all_emails ? "all": `${abbreviateNumber(emailsCount || 0, 2, { padding: false })}} ${emailsCount !== 1 ? "emails" : "email"}`;
    const title = originalName || name;
    const url = `${BASE_URL}/download/${id}`;

    const copyHandler = ()=> {
        if(typeof onCopyButtonClick === "function" && onCopyButtonClick() && copyButtonRef.current && !timeout){
            window.clearTimeout(timeout);
            copyButtonRef.current.classList?.add(styles.copied);

            const newTimeOut = window.setTimeout(()=> {
                copyButtonRef.current?.classList?.remove(styles.copied);
                setTimeout(null);
            }, 1000);

            setTimeout(newTimeOut);
        }
    }

    return(
        <div title={title} className={`flex flex-col ${styles["file"]} custom-container`}>
            <div className={`flex flex-row flex-wrap justify-between items-center ${styles["file-header"]}`}>
                <div className={`flex flex-row items-center ${styles["file-title-container"]}`}>
                    <a href={url} target="_blank" rel={"noreferrer"}>
                        <h2 className={styles["file-title"]}>{originalName}</h2>
                    </a>
                    <button ref={copyButtonRef} className={`flex flex-row items-center ${styles["copy-button"]}`} onClick={copyHandler}>
                        <CopySvg />
                        <span>{!timeout ? "copy" : "copied"}</span>
                    </button>
                </div>
                <div className={`flex flex-row ${styles["file-buttons"]}`}>
                    <Link to={`edit/${id}`} onClick={onLinkClick}>
                        <div className="flex flex-col items-center">
                            <EditSvg stroke={"#55596E"} />
                            <span>edit</span>
                        </div>
                    </Link>
                    <Link to={""} onClick={()=> download(id, originalName)}>
                        <div className="flex flex-col items-center">
                            <DownloadSvg />
                            <span>download</span>
                        </div>
                    </Link>
                    <Link to={`delete/${id}`} data-type="delete" onClick={onLinkClick}>
                        <div className="flex flex-col items-center">
                            <DeleteSvg />
                            <span>delete</span>
                        </div>
                    </Link>
                </div>
            </div>
            <div className={`flex flex-row flex-wrap items-center ${styles["file-info"]}`}>
                <Link title={`${title} - downloads`} to={`downloads-list/${id}`}>
                    <div className={`flex flex-row items-center ${styles["info"]}`}>
                        <DownloadsSvg />
                        <span>{abbreviateNumber(downloadsCount || 0, 2, { padding: false })} {downloadsCount !== 1 ? "downloads" : "download"}</span>
                    </div>
                </Link>
                <Link title={`${title} - allowed emails`} to={`emails-list/${id}`}>
                    <div className={`flex flex-row items-center ${styles["info"]}`}>
                        <EmailSvg />
                        <span>{allowedEmails}</span>
                    </div>
                </Link>
                <div title={`${title} - creation date`} className={`flex flex-row items-center ${styles["info"]}`}>
                    <CreatedAtSvg />
                    <span>{createdAt}</span>
                </div>
                <div title={`${title} - last update`} className={`flex flex-row items-center ${styles["info"]}`}>
                    <UpdatedAtSvg />
                    <span>{updatedAt || "-"}</span>
                </div>
            </div>
        </div>
    );
}