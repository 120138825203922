import { useEffect } from "react";
import styles from "./modal.module.css";

export default function Modal({children, onClick, ...props}){
    useEffect(()=> {
        function KeyPressHandler(ev){
            if(["esc", "escape"].includes(ev.key?.toLowerCase()) && onClick){
                onClick();
            }
        }

        document.addEventListener("keyup", KeyPressHandler);

        return ()=> {
            document.removeEventListener("keyup", KeyPressHandler);
        }
    }, [onClick]);

    return(
        <section 
            {...props}
            onClick={onClick}
            className={`fixed flex items-center justify-center h-screen w-screen ${styles.wrapper} ${props.className || ""}`}
        >
            {children}
        </section>
    )
}