import axios from "axios";

export const BASE_URL = window.location.origin;
// export const BASE_URL = "http://localhost:3000";
export const API_BASE_URL = `${BASE_URL}/api`;
export const NO_RETRY_HEADER = 'x-no-retry'

const AxiosInstance = axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true,
    timeout: 10_000,
    headers: {
        options: {
            'Access-Control-Allow-Origin': '*',
        }
    }
});

AxiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("accessToken");
        config.headers.Authorization = `Bearer ${token}`
        return config;
    }
);

AxiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if(
            !axios.isCancel(error) && 
            axios.isAxiosError(error) && 
            error.status === 401 && 
            error.config &&
            error.config?.headers[NO_RETRY_HEADER] !== "true"
        ){
            error.config.headers[NO_RETRY_HEADER] = "true";
            return Promise.resolve();
        }

        return Promise.reject(error);
    }
);

export default AxiosInstance;
