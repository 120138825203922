import useGoBack from "../../hooks/useGoBack";
import styles from "./error.module.css";

export default function ErrorElement(){
    const { goBack } = useGoBack();

    return(
        <div className={`flex flex-col items-center justify-center ${styles["container"]}`}>
            <span className={styles["title"]}>
                something went wrong !
            </span>
            <button className={styles["button"]} onClick={goBack}>
                Go Back
            </button>
        </div>
    )
}