import Modal from "../../../components/Modal";
import useEmails from "../../../hooks/useEmails";
import styles from "./emails.module.css";
import { ReactComponent as LoadMoreSvg } from "../../../components/svgs/loadmore.svg";
import { Link } from "react-router-dom";
import EmailsList from "../../../components/Lists";

export default function EmailsPage(){
    const { items, userId, loading, search, isSearch, updating, hasMore, selectAll, selected, goBack, approve, setSearch, deleteHandler, loadMore, refresh, resetMode, searchHandler, selectAllHandler, selectHandler, updateEmailHandler, approveBulkHandler } = useEmails();

    if(!userId) return;

    return(
        <Modal onClick={goBack} className={styles["modal"]}>
            <div 
                onClick={(ev)=> ev.stopPropagation && ev.stopPropagation()} 
                className={`flex flex-col custom-container ${styles["container"]}`}
            >
                <div className={`flex flex-col ${styles["header"]}`}>
                    <h3 className={styles["title"]}>list of emails:</h3>
                    <div className={`relative flex flex-row ${styles["search-input"]}`}>
                        <input 
                            type="text" 
                            placeholder="e.g. email, company name ..." 
                            value={search.value}  
                            onChange={(ev)=> setSearch(prev => ({
                                ...prev,
                                value: ev.target.value,
                            }))}
                            disabled={loading}
                        />
                        <button onClick={searchHandler} disabled={loading}>
                            search
                        </button>
                        {
                            isSearch &&
                            <button onClick={resetMode} className={styles["reset"]} disabled={loading}>
                                reset
                            </button>
                        }
                    </div>
                </div>
                <div className={`flex flex-col ${styles["emails-wrapper"]}`}>
                    <div className={`flex flex-row items-start ${styles["emails-container"]}`}>
                        <table className={`custom-container ${styles["emails"]}`}>
                            <thead>
                                <tr className={`${styles["email"]} ${styles["head"]}`}>
                                    <th>
                                        <input 
                                            type="checkbox" 
                                            name="all-emails-checked"
                                            onChange={selectAllHandler}
                                            checked={selectAll}
                                        />
                                    </th>
                                    <th>email</th>
                                    <th>company</th>
                                    <th>company gsm</th>
                                    <th>name</th>
                                    <th>gsm</th>
                                    <th>address</th>
                                    <th>approved</th>
                                    <th>verified</th>
                                </tr>
                            </thead>
                            <EmailsList 
                                items={items}
                                selected={selected}
                                updating={updating}
                                selectHandler={selectHandler}
                                approve={approve}
                                editable={{
                                    value: true,
                                    onSave: updateEmailHandler
                                }}
                            />
                        </table>
                    </div>
                    {
                        hasMore &&
                        <section className="flex flex-row justify-center items-center">
                            <button
                                type="button"
                                value={"load more"}
                                className={`flex flex-row items-center ${styles["load-more-button"]}`}
                                onClick={loadMore}
                            >
                                <LoadMoreSvg />
                                <span>load more</span>
                            </button>
                        </section>
                    }
                </div>
                <div className={`flex flex-row items-center justify-end ${styles["buttons"]}`}>
                    <button 
                        type="button"
                        value="cancel"
                        name="cancel"
                        className={`${styles["button"]} ${styles["cancel-button"]}`}
                        onClick={goBack}
                    >
                        close
                    </button>
                    <button 
                        type="button"
                        value="cancel"
                        name="cancel"
                        className={`${styles["button"]} ${styles["refresh-button"]}`}
                        onClick={refresh}
                    >
                        refresh
                    </button>
                    <Link 
                        className={`${styles["button"]} ${styles["import-button"]}`}
                        to={"import"}
                    >
                        import
                    </Link>
                    {
                        selected.size > 0 &&
                        <button 
                            type="button"
                            value="delete"
                            name="delete"
                            className={`${styles["button"]} ${styles["delete-button"]}`}
                            onClick={deleteHandler}
                        >
                            delete
                        </button>
                    }
                    {
                        selected.size > 0 &&
                        <button 
                            type="button"
                            value="approve"
                            name="approve"
                            className={`${styles["button"]} ${styles["delete-button"]}`}
                            onClick={approveBulkHandler}
                        >
                            approve
                        </button>
                    }
                </div>
            </div>
        </Modal>
    );
}