import { USER_INITIAL_DATA } from "..";

export const userActions = {
    USER_AUTHENTICATED: "USER_AUHTENTICATED",
    EMAIL_MANAGER_INFORMATION_UPDATED: "EMAIL_MANAGER_INFORMATION_UPDATED",
    LOADING: "LOADING",
    LOAD_ENDED: "LOAD_ENDED",
    LOGOUT: "LOGOUT"
}

export function userReducer(state, action){
    if(typeof state !== "object"){
      state = {
        ...USER_INITIAL_DATA
      }
    }
  
    switch(action.type){
        case userActions.USER_AUTHENTICATED: {
            return({
              ...state,
              user: {
                ...action.payload || {},
              },
              isAuth: !!action.payload,
              loading: false
            });
        }
        case userActions.EMAIL_MANAGER_INFORMATION_UPDATED: {
            return({
                ...state,
                user: {
                  ...state.user || {},
                  emails_manager: {
                    ...state.user.emails_manager || {},
                    ...action.payload || {}
                  }
                },
                loading: false
              });
        }
        case userActions.LOADING: {
            return({
                ...state,
                loading: true
            });
        }
        case userActions.LOAD_ENDED: {
            return({
                ...state,
                loading: false
            });
        }
        case userActions.LOGOUT: {
            return({
                ...USER_INITIAL_DATA
            });
        }
        default: break;
    }
  
    return state;
}