import AxiosInstance from "../config/api";
import { userActions } from "../context/reducers";
import { emailsActions } from "../context/reducers/emails";
import { filesActions } from "../context/reducers/files";
import AxiosErrorHandler from "../helpers/errors";

export async  function login(data, dispatch){
    dispatch({
        type: userActions.LOADING
    });

    try{
        const response = await AxiosInstance
            .post(`/user/login`, data, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
        
        localStorage.setItem("accessToken", response.data.token);
        dispatch({
            type: userActions.USER_AUTHENTICATED,
            payload: response.data.user
        });
    }catch(err){
        dispatch({
            type: userActions.LOAD_ENDED
        });
    
        return AxiosErrorHandler(err);
    }
}

export async function register(data, dispatch){
    dispatch({
        type: userActions.LOADING
    });

    try{
        const response = await AxiosInstance
            .post(`/user/register`, data, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
        
        localStorage.setItem("accessToken", response.data.token);
        dispatch({
            type: userActions.USER_AUTHENTICATED,
            payload: response.data.user
        });
    }catch(err){
        dispatch({
            type: userActions.LOAD_ENDED
        });
    
        return AxiosErrorHandler(err);
    }
};

export async function auth(dispatch){
    dispatch({
        type: userActions.LOADING
    });

    try{
        const response = await AxiosInstance
            .get(`/user/auth`, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
        
        localStorage.setItem("accessToken", response.data.token);
        dispatch({
            type: userActions.USER_AUTHENTICATED,
            payload: response.data.user
        });
    }catch(err){
        dispatch({
            type: userActions.LOAD_ENDED
        });
    
        return AxiosErrorHandler(err);
    }
};

export async function getFiles(page, dispatch){
    dispatch({
        type: filesActions.LOADING
    });

    try{
        const response = await AxiosInstance
            .get(`/file?page=${page || 1}`, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
        
        dispatch({
            type: filesActions.FILES_RETRIEVED,
            payload: response.data
        });
    }catch(err){
        dispatch({
            type: filesActions.FILES_RETRIEVED_FAIL
        });
    
        return AxiosErrorHandler(err);
    }
}

export async function getSearchFiles(page, query, dispatch){
    dispatch({
        type: filesActions.LOADING
    });

    try{
        const response = await AxiosInstance
            .get(`/file?page=${page || 1}&query=${query}`, {
                headers: {
                    "Content-Type": "application/json"
                },
            });
        
        dispatch({
            type: filesActions.SEARCH_FILES_RETRIEVED,
            payload: response.data
        });
    }catch(err){
        dispatch({
            type: filesActions.SEARCH_FILES_RETRIEVED_FAIL
        });
    
        return AxiosErrorHandler(err);
    }
}

export async function createFile(data, dispatch){
    dispatch({
        type: filesActions.LOADING
    });

    try{
        const response = await AxiosInstance.post(`/file`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Accept": "*/*"
            }
        });
        
        dispatch({
            type: filesActions.FILE_CREATED,
            payload: response.data?.files
        });
    }catch(err){
        dispatch({
            type: filesActions.LOAD_ENDED
        });
    
        return AxiosErrorHandler(err);
    }
}

export async function updateFile(fileId, data, dispatch){
    dispatch({
        type: filesActions.LOADING
    });

    try{
        const response = await AxiosInstance.put(`/file/${fileId}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Accept": "*/*",
            }
        });
        
        dispatch({
            type: filesActions.FILE_UPDATED,
            payload: response.data?.file
        });
    }catch(err){
        dispatch({
            type: filesActions.LOAD_ENDED
        });
    
        return AxiosErrorHandler(err);
    }
}

export async function deleteFile(fileId, dispatch){
    dispatch({
        type: filesActions.LOADING
    });

    try{
        await AxiosInstance.delete(`/file/${fileId}`);
        
        dispatch({
            type: filesActions.FILE_DELETED,
            payload: {
                id: fileId
            }
        });
    }catch(err){
        dispatch({
            type: filesActions.LOAD_ENDED
        });
    
        return AxiosErrorHandler(err);
    }
}

export async function download(fileId, fileName, dispatch){
    dispatch({
        type: filesActions.LOADING
    });

    try{
        const response = await AxiosInstance
            .get(`/file/${fileId}/download`, {
                headers: {
                    "Content-Type": "application/json"
                },
                responseType: "blob"
            });

        const blob = response.data;

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        
        link.href = url;
        link.download = fileName || "download";
        link.click();

        URL.revokeObjectURL(url);
        
        dispatch({
            type: filesActions.LOAD_ENDED
        });
    }catch(err){
        dispatch({
            type: filesActions.LOAD_ENDED
        });
    
        return AxiosErrorHandler(err);
    }
}

export async function getFileDownloads(fileId, page, dispatch){
    dispatch({
        type: filesActions.LOADING
    });

    try{
        const response = await AxiosInstance
            .get(`/file/${fileId}/downloads?page=${page || 1}`, {
                headers: {
                    "Content-Type": "application/json"
                },
            });
        
        dispatch({
            type: filesActions.LOAD_ENDED
        });
        return response.data;
    }catch(err){
        dispatch({
            type: filesActions.LOAD_ENDED
        });
    
        return AxiosErrorHandler(err);
    }
}

export async function searchFileDownloads(fileId, query, page, dispatch){
    dispatch({
        type: filesActions.LOADING
    });

    try{
        const response = await AxiosInstance
            .get(`/file/${fileId}/downloads?page=${page || 1}&query=${query}`, {
                headers: {
                    "Content-Type": "application/json"
                },
            });
        
        dispatch({
            type: filesActions.LOAD_ENDED
        });
        return response.data;
    }catch(err){
        dispatch({
            type: filesActions.LOAD_ENDED
        });
    
        return AxiosErrorHandler(err);
    }
}

export async function getEmails(page, dispatch){
    dispatch({
        type: emailsActions.LOADING
    });

    try{
        const response = await AxiosInstance
            .get(`/email?page=${page || 1}`, {
                headers: {
                    "Content-Type": "application/json"
                },
            });
        
        dispatch({
            type: emailsActions.EMAILS_RETRIEVED,
            payload: response.data
        });
    }catch(err){
        dispatch({
            type: emailsActions.EMAILS_RETRIEVED_FAIL
        });
    
        return AxiosErrorHandler(err);
    }
}

export async function deleteEmails(ids, dispatch){
    dispatch({
        type: emailsActions.LOADING
    });

    ids = ids instanceof Map ? 
        Array.from(ids.entries())
            .filter(([_, value])=> value)
            .map(([key, _])=> key) : Array.isArray(ids) ? ids : [];

    let start = 0, end = 500;
    let slice = ids.slice(start, end);
    let deleted = 0;

    try{
        while(slice.length > 0){
            await AxiosInstance
                .delete(`/email`, {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    data: ids
                });

            dispatch({
                type: emailsActions.EMAILS_DELETED,
                payload: slice
            });

            start += 500;
            end += 500;
            deleted += slice.length;
            slice = ids.slice(start, end);
        }

        dispatch({
            type: emailsActions.LOAD_ENDED
        });

        return {
            refresh: true
        };
    }catch(err){
        dispatch({
            type: emailsActions.EMAILS_DELETEION_FAIL
        });
    
        return {
            ...AxiosErrorHandler(err),
            refresh: deleted > 0,
        };
    }
}

export async function getSearchEmails(page, query, dispatch){
    dispatch({
        type: emailsActions.LOADING
    });

    try{
        const response = await AxiosInstance
            .get(`/email?page=${page || 1}&query=${query}`, {
                headers: {
                    "Content-Type": "application/json"
                },
            });
        
        dispatch({
            type: emailsActions.SEARCH_EMAILS_RETRIEVED,
            payload: response.data
        });
    }catch(err){
        dispatch({
            type: emailsActions.SEARCH_EMAILS_RETRIEVED_FAIL
        });
    
        return AxiosErrorHandler(err);
    }
}

export async function createEmails(data, dispatch){
    dispatch({
        type: emailsActions.LOADING
    });

    try{
        await AxiosInstance
            .post(`/email`, data, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
        
        dispatch({
            type: emailsActions.LOAD_ENDED
        });
    }catch(err){
        dispatch({
            type: emailsActions.LOAD_ENDED
        });
    
        return AxiosErrorHandler(err);
    }
}

export async function approveEmail(emailId, data, dispatch){
    dispatch({
        type: emailsActions.UPDATING_EMAIL,
        payload: {
            id: emailId
        }
    });
    try{
        await AxiosInstance
            .put(`/email/${emailId}/approve`, data, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
        
        dispatch({
            type: emailsActions.EMAIL_APPROVED,
            payload: {
                id: emailId
            }
        });
    }catch(err){
        dispatch({
            type: emailsActions.EMAIL_UPDATE_FAIL,
            payload: {
                id: emailId
            }
        });
    
        return AxiosErrorHandler(err);
    }
}

export async function approveEmails(ids, dispatch){
    ids = ids instanceof Map ? 
        Array.from(ids.entries())
            .filter(([_, value])=> value)
            .map(([key, _])=> key) : Array.isArray(ids) ? ids : [];

    dispatch({
        type: emailsActions.APPROVING_EMAILS,
        payload: {
            ids
        }
    });
    try{
        await AxiosInstance
            .put(`/email/approve`, {
                emails: ids
            }, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
        
        dispatch({
            type: emailsActions.EMAILS_APPROVED,
            payload: {
                ids
            }
        });
    }catch(err){
        dispatch({
            type: emailsActions.EMAIL_APPROVE_FAILED,
            payload: {
                ids
            }
        });
    
        return AxiosErrorHandler(err);
    }
}

export async function updateEmail(emailId, data, dispatch){
    dispatch({
        type: emailsActions.UPDATING_EMAIL,
        payload: {
            id: emailId
        }
    });
    try{
        const response = await AxiosInstance
            .put(`/email/update/${emailId}`, data, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
        
        dispatch({
            type: emailsActions.EMAIL_UPDATED,
            payload: {
                id: emailId,
                email: response.data?.email
            }
        });

        return true;
    }catch(err){
        dispatch({
            type: emailsActions.EMAIL_UPDATE_FAIL,
            payload: {
                id: emailId
            }
        });
    
        AxiosErrorHandler(err);

        return false;
    }
}

export async function getFileEmails(fileId, page, dispatch){
    dispatch({
        type: userActions.LOADING
    });

    try{
        const response = await AxiosInstance
            .get(`/file/${fileId}/email?page=${page || 1}`, {
                headers: {
                    "Content-Type": "application/json"
                },
            });
        
        dispatch({
            type: userActions.LOAD_ENDED,
        });

        return response.data;
    }catch(err){
        dispatch({
            type: userActions.LOAD_ENDED,
        });
    
        return AxiosErrorHandler(err);
    }
}

export async function approveSubscription(fileId, emailId, data, dispatch){
    dispatch({
        type: filesActions.UPDATING_FILE_EMAIL,
        payload: {
            id: `${emailId}-${fileId}`
        }
    });
    try{
        await AxiosInstance
            .put(`/file/${fileId}/email/${emailId}/approve`, data, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
        
        dispatch({
            type: filesActions.FILE_EMAIL_APPROVED,
            payload: {
                id: `${emailId}-${fileId}`
            }
        });
    }catch(err){
        dispatch({
            type: filesActions.FILE_EMAIL_UPDATE_FAIL,
            payload: {
                id: `${emailId}-${fileId}`
            }
        });
    
        return AxiosErrorHandler(err);
    }
}

export async function updateEmailsManager(userId, updates, dispatch){
    dispatch({
        type: userActions.LOADING
    });
    try{
        await AxiosInstance
            .put(`/user/${userId}/zoho`, updates, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
        
        dispatch({
            type: userActions.EMAIL_MANAGER_INFORMATION_UPDATED,
            payload: updates
        });

    }catch(err){
        dispatch({
            type: userActions.LOAD_ENDED
        });
    
        return AxiosErrorHandler(err);
    }
}