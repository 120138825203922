import styles from "./dashboard.module.css";
import { ReactComponent as LoadMoreSvg } from "../../../components/svgs/loadmore.svg";
import { Link, Outlet } from "react-router-dom";
import FilesItem from "../../../components/ListItem/files";
import { BASE_URL } from "../../../config/api";
import useFiles from "../../../hooks/useFiles";

export default function Dashboard(){
    const { items, hasMore, search, loading, user, isSearch, showBadge, setSearch, downloadFile, hideBadge, loadMore, logout, onLinkClick, onCopyButtonClick, resetMode, searchHandler } = useFiles();

    const unapprovedEmailsCount = showBadge ? Math.max(Math.min(user.unapprovedEmails || 0, 9), 0) : 0;
    const unapprovedEmails = showBadge && unapprovedEmailsCount > 0 ? (user.unapprovedEmails || 0) >= 9 ? "9+" : `${unapprovedEmailsCount}` : "";

    if(!user) return null;
 
    return(
        <div className={`flex flex-col ${styles["container"]}`}>
            <header className={`flex flex-col sm:flex-row justify-between flex-wrap items-center ${styles["header"]}`}>
                <div className={`flex flex-row items-center ${styles["nav"]}`}>
                    <img 
                        src={`${BASE_URL}/api/user/${user.id}/logo`} 
                        alt={`${user.name} Logo`}
                        className={styles["logo"]}
                    />
                </div>
                <nav className={`flex flex-row items-center ${styles["nav"]}`}>
                    <Link to={"emails"} 
                        className={`${styles["nav-link"]} ${styles["emails"]}`}
                        onClick={hideBadge}
                    >
                        <span>emails</span>
                        {
                            unapprovedEmails &&
                            <span className={styles["badge"]}>{unapprovedEmails}</span>
                        }
                    </Link>
                    <Link to={"create"} className={`${styles["nav-link"]} ${styles["create-link"]}`}>
                        new file
                    </Link>
                    <Link to={"emails-manager"} className={`${styles["nav-link"]} ${styles["zoho-link"]}`}>
                        zoho
                    </Link>
                    <button type="button" onClick={logout} className={`${styles["nav-link"]} ${styles["logout-link"]}`}>
                        logout
                    </button>
                </nav>
            </header>
            <main id="main" className={`flex flex-col content-container mx-auto ${styles["main"]}`}>
                <div className={`relative flex flex-row ${styles["search-input"]}`}>
                    <input 
                        type="text" 
                        placeholder="e.g. email, company name ..." 
                        value={search.value}  
                        onChange={(ev)=> !loading && setSearch(prev => ({
                            ...prev,
                            value: ev.target.value
                        }))}
                        disabled={loading}
                    />
                    <button onClick={searchHandler} disabled={loading}>
                        search
                    </button>
                    {
                        isSearch &&
                        <button onClick={resetMode} className={styles["reset"]} disabled={loading}>
                            reset
                        </button>
                    }
                </div>
                <section className={`flex flex-col ${styles["files"]}`}>
                    {Array.isArray(items) && items.map(file => {
                        return(
                            <FilesItem 
                                key={file.id}
                                file={file} 
                                download={downloadFile}
                                onLinkClick={onLinkClick}
                                onCopyButtonClick={()=> typeof file.id === "string" && onCopyButtonClick(`${BASE_URL}/download/${file.id}`)}
                            />
                        );
                    })}
                </section>
                {
                    hasMore &&
                    <section className="flex flex-row justify-center items-center">
                        <button
                            type="button"
                            value={"load more"}
                            className={`flex flex-row items-center ${styles["load-more-button"]}`}
                            onClick={loadMore}
                        >
                            <LoadMoreSvg />
                            <span>load more</span>
                        </button>
                    </section>
                }
            </main>
            <Outlet />
        </div>
    );
}