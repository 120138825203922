import EmailsItem from "../../../components/ListItem/emails";
import Modal from "../../../components/Modal";
import useFileDownloads from "../../../hooks/useFileDownloads";
import styles from "./emails.module.css";
import { ReactComponent as LoadMoreSvg } from "../../../components/svgs/loadmore.svg";
import { getFileDownloads, searchFileDownloads } from "../../../api";

export default function FileDownloads(){
    const { file, fileId, userId, title, goBack, data, searchHandler, resetMode, hasMore, search, isSearch, page, dispatch, loading, setSearch, setState } = useFileDownloads();

    if(!userId || !file) return null; 

    const loadMore = ()=> {
        if(loading || !hasMore) return;

        isSearch ? search.length > 0 && 
            searchFileDownloads(fileId, search.prevSearch, page, dispatch)
                .then(downloads => {
                    if(Array.isArray(downloads?.data)){
                        setState(prev => ({
                            ...prev,
                            searchData: {
                                ...prev.searchData,
                                items: prev.searchData.items.concat(downloads.data),
                                hasMore: downloads.hasMore,
                                page: downloads.page
                            },
                            isSearch: true,
                            firstApiCallAlreadyMade: true
                        }));
                    }
                }) : 
            getFileDownloads(fileId, page, dispatch).then(downloads => {
                if(Array.isArray(downloads?.data)){
                    setState(prev => ({
                        ...prev,
                        data: {
                            ...prev.data,
                            items: prev.data.items.concat(downloads.data),
                            hasMore: downloads.hasMore,
                            page: downloads.page
                        },
                        isSearch: false,
                        firstApiCallAlreadyMade: true
                    }));
                }
            });
    }

    return(
        <Modal onClick={goBack} className={styles["modal"]}>
            <div onClick={(ev)=> ev.stopPropagation && ev.stopPropagation()} className={`flex flex-col custom-container ${styles["container"]}`}>
                <div className={`flex flex-col ${styles["header"]}`}>
                    <div className={`flex flex-col items-center justify-center ${styles["content"]}`}>
                        <h2 className={styles["file-title"]} title={title}>{title} - downloads</h2>
                    </div>
                    <div className={`relative flex flex-row ${styles["search-input"]}`}>
                        <input 
                            type="text" 
                            placeholder="e.g. email, company name ..." 
                            value={search.value}  
                            onChange={(ev)=> setSearch(ev.target.value)}
                            disabled={loading}
                        />
                        <button onClick={searchHandler} disabled={loading}>
                            search
                        </button>
                        {
                            isSearch &&
                            <button onClick={resetMode} className={styles["reset"]} disabled={loading}>
                                reset
                            </button>
                        }
                    </div>
                </div>
                <div className={`flex flex-col ${styles["emails-wrapper"]}`}>
                    <div className={`flex flex-row items-start ${styles["emails-container"]}`}>
                        <table className={`custom-container ${styles["emails"]}`}>
                            <thead>
                                <tr className={`${styles["email"]} ${styles["head"]}`}>
                                    <th>email</th>
                                    <th>company</th>
                                    <th>company gsm</th>
                                    <th>name</th>
                                    <th>gsm</th>
                                    <th>address</th>
                                    <th>downloads</th>
                                    <th>approved</th>
                                    <th>verified</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Array.isArray(data) &&
                                    data.map((download) => {
                                        const email = download?.email;
                                        if(typeof email !== "object") return null;

                                        return(
                                            <EmailsItem 
                                                key={email.id}
                                                item={{...email, downloadsNumber: download.downloadsNumber || 1}}
                                            />
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    {
                        hasMore &&
                        <section className="flex flex-row justify-center items-center">
                            <button
                                type="button"
                                value={"load more"}
                                className={`flex flex-row items-center ${styles["load-more-button"]}`}
                                onClick={loadMore}
                            >
                                <LoadMoreSvg />
                                <span>load more</span>
                            </button>
                        </section>
                    }
                </div>
                <div className={`flex flex-row items-center justify-end ${styles["buttons"]}`}>
                    <button 
                        type="button"
                        value="cancel"
                        name="cancel"
                        className={`${styles["button"]} ${styles["cancel-button"]}`}
                        onClick={goBack}
                    >
                        close
                    </button>
                </div>
            </div>
        </Modal>
    );
}