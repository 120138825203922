import { createContext, useReducer } from "react";

const INITIAL_STATE = {
    user: null,
    filesEmails: {
        updating: new Map()
    },
    loading: false,
    isAuth: false,
    firstApiCallAlreadyMade: false
};

export const UserContext = createContext(INITIAL_STATE);
export const UserDispatchContext = createContext(useReducer);
export const USER_INITIAL_DATA = INITIAL_STATE;