import { useContext, useState } from "react";
import Modal from "../../../components/Modal";
import styles from "./create.module.css";
import { validateKeyValue } from "../../../helpers/validators";
import useGoBack from "../../../hooks/useGoBack";
import { createFile } from "../../../api";
import { bytesToMB } from "../../../helpers";
import { FilesDispatchContext } from "../../../context/files";
import useLoading from "../../../hooks/useLoading";

export const fileType = "image/*, .pdf, .csv, .xls, .xlsx, .ppt, .pptx, .doc, .docx, .txt, .rtf, .odt, .ods, .odp, .key, .md, .text, .txt, .tex, .zip, .rar, .7z, application/pdf, text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain, application/rtf, application/vnd.oasis.opendocument.text, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.presentation, application/vnd.apple.keynote, text/markdown, application/octet-stream, application/x-gzip, application/x-bzip2";

export default function CreatePage(){
    const [state, setState] = useState({
        files: [],
        password: "",
        errors: {
            files: "",
            password: "",
            global: ""
        },
        filesSize: 0,
        filesDialogOpen: false,
        showPassword: false
    });

    const loading = useLoading();
    const dispatch = useContext(FilesDispatchContext);

    let { goBack } = useGoBack();

    goBack = loading || state.filesDialogOpen ? ()=> false : goBack;

    const handleSubmit = (event)=> {
        event?.preventDefault();

        if(loading) return;

        const { files, password } = state;

        const formData = new FormData();

        let filesValidation = files.length === 0 ? "required field" : state.filesSize > 100 ? "files total size can not exceed 100mb" : "";

        if(!filesValidation){
            for(let i = 0; i < files.length && i < 5; i++){
                const file = files[i];
                formData.append("files", file);
            }
        }

        const passwordValidation = validateKeyValue("password", password);

        if(!passwordValidation){
            formData.append("password", password);
        }

        setState(prev => ({
            ...prev,
            errors: {
                ...prev.errors,
                files: filesValidation,
                password: passwordValidation,
            }
        }));

        if(!formData.get("files") || !formData.get("password")) return;

        createFile(formData, dispatch)
            .then(result => {
                if(!result){
                    return goBack();
                }

                setState(prev => ({
                    ...prev,
                    errors: {
                        ...prev.errors,
                        global: result.message
                    }
                }));
            });
    }

    const passwordChangeHandler = (ev)=> {
        setState(prev => ({
            ...prev, 
            password: ev.target.value, 
            errors: {
                ...prev.errors,
                password: validateKeyValue("password", ev.target.value)
            }
        }));
    }

    const filesChangeHandler = (ev)=> {
        const files = [];
        let totalSize = 0;
        
        for(let i = 0; i < ev.target.files.length; i++){
            const file = ev.target.files.item(i);

            if(!file){
                continue;
            }

            const size = bytesToMB(file.size);
            totalSize += size;
            files.push(file);
        }

        const validation = totalSize > 100 ? "files total size can not exceed 100mb" : "";
        
        setState(prev => ({
            ...prev, 
            files, errors: {
                ...prev.errors,
                files: validation
            }, 
            filesSize: totalSize,
            filesDialogOpen: false
        }));
    }

    return(
        <Modal onClick={goBack}>
            <form 
                onClick={(ev)=> ev.stopPropagation && ev.stopPropagation()} 
                onSubmit={handleSubmit} 
                className={`custom-container flex flex-col ${styles["container"]}`}
            >
                <div className={`flex flex-col ${styles["header"]}`}>
                    <h3 className={styles.title}>create file:</h3>
                </div>
                <label className={styles["label"]}>
                    <span>file</span>
                    <input 
                        id="file"
                        type="file" 
                        name="files" 
                        accept={fileType}
                        multiple
                        max={5}
                        disabled={loading}
                        className={styles["input"]}
                        onChange={filesChangeHandler}
                        onFocus={()=> setState(prev => ({
                            ...prev,
                            filesDialogOpen: true
                        }))}
                        onBlur={()=> setState(prev => ({
                            ...prev,
                            filesDialogOpen: false
                        }))}
                    />
                    <span className={styles["error"]}>{state.errors.files || ""}</span>
                </label>
                <label className={styles["label"]}>
                    <div className={`flex flex-row justify-between items-center gap-[5px]`}>
                        <span>password</span>
                        <span 
                            onClick={()=> setState(prev => ({
                                ...prev,
                                showPassword: !prev.showPassword
                            }))} 
                            data-show={state.showPassword} 
                            className={styles["show-hide"]}
                        >
                            {state.showPassword ? "hide" : "show"}
                        </span>
                    </div>
                    <input 
                        id="password"
                        type={state.showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Pass@Word123"
                        className={styles["input"]}
                        value={state.password}
                        disabled={loading}
                        onChange={passwordChangeHandler}
                    />
                    <span className={styles["error"]}>{state.errors.password || ""}</span>
                </label>
                <span className={styles["error"]}>{state.errors.global || ""}</span>
                <div className={`flex flex-row items-center justify-end ${styles["buttons"]}`}>
                    <button 
                        type="button"
                        value="cancel"
                        name="cancel"
                        className={`${styles["button"]} ${styles["cancel-button"]}`}
                        disabled={loading}
                        onClick={goBack}
                    >
                        cancel
                    </button>
                    <button 
                        type="submit"
                        value="create"
                        className={`${styles["button"]} ${styles["submit-button"]}`}
                        disabled={loading}
                    >
                        create
                    </button>
                </div>
            </form>
        </Modal>
    );
}