import { useContext, useEffect, useState } from "react";
import { approveEmail, approveEmails, deleteEmails, getEmails, getSearchEmails, updateEmail } from "../api";
import { UserContext } from "../context";
import useGoBack from "./useGoBack";
import { EmailsContext, EmailsDispatchContext } from "../context/emails";
import useLoading from "./useLoading";
import { emailsActions as actions } from "../context/reducers/emails";

export default function useEmails(){
    const [search, setSearch] = useState({
        value: "",
        prevSearch: null
    });

    const { goBack } = useGoBack();

    const loading = useLoading();
    const { user } = useContext(UserContext);
    const emails = useContext(EmailsContext);
    const dispatch = useContext(EmailsDispatchContext);
    
    const { data: normalData, searchData, isSearch, updating, } = emails || {};
    const { firstApiCallAlreadyMade } = normalData || {};

    const data = (isSearch ? searchData : normalData) || {};
    const { hasMore, page, selected, selectAll } = data;
    const items = data.items;
    const userId = user.id;

    useEffect(()=> {
        if(!userId || firstApiCallAlreadyMade || loading) return;

        getEmails(1, dispatch);
        dispatch({
            type: actions.RESET_EMAILS_MODE
        });
    }, [userId, loading, firstApiCallAlreadyMade, dispatch]);

    const loadMore = ()=> {
        if(loading || !hasMore) return;

        isSearch ? 
            search.length > 0 && getSearchEmails(page, search.prevSearch.trim(), dispatch) : getEmails(page, dispatch);
    }

    const deleteHandler = ()=> {
        if(loading || selected.size === 0) return;

        deleteEmails(selected, dispatch)
            .then(result => {
                if(result.refresh){
                    getEmails(1, dispatch)
                }
            });
    }

    const approveBulkHandler = ()=> {
        if(loading || selected.size === 0) return;

        approveEmails(selected, dispatch);
    }

    const updateEmailHandler = async (emailId, data)=> {
        if(loading || (updating.has(emailId) && updating.get(emailId))) return;

        return await updateEmail(emailId, data, dispatch);
    }

    const selectAllHandler = ()=> {
        if(loading) return;

        dispatch({
            type: isSearch ? actions.CHECK_ALL_SEARCH_EMAILS : actions.CHECK_ALL_EMAILS
        });            
    }

    const selectHandler = (id, value)=> {
        if(loading) return;

        value = !!value;
        dispatch({
            type: isSearch ? actions.CHECK_SEARCH_EMAIL : actions.CHECK_EMAIL,
            payload: {
                id,
                value
            }
        });
    }

    const refresh = ()=> {
        if(loading) return;
    
        isSearch ? 
            search.length > 0 && getSearchEmails(1, search.prevSearch.trim(), dispatch) : getEmails(1, dispatch);
    }

    const searchHandler = ()=> {
        if(loading || search.value.trim() === search.prevSearch?.trim()) return;

        if(search.value.trim().length === 0) {
            dispatch({
                type: actions.RESET_EMAILS_MODE
            });
            return;
        };
        
        getSearchEmails(1, search.value.trim(), dispatch)
            .then(result => {
                if(!result){
                    setSearch(prev => ({
                        ...prev,
                        prevSearch: search.value.trim()
                    }))
                }
            });
    }

    const resetMode = ()=> {
        if(loading) return;
        
        dispatch({
            type: actions.RESET_EMAILS_MODE
        });

        setSearch(prev => ({
            ...prev,
            prevSearch: null
        }));
    }

    const approve = (approved, id)=> {
        approveEmail(id, {approved}, dispatch)
    }


    return { items, userId, loading, hasMore, selectAll, selected, search, isSearch, updating, setSearch, goBack, dispatch, resetMode, deleteHandler, approveBulkHandler, searchHandler, refresh, selectAllHandler, loadMore, approve, selectHandler, updateEmailHandler };
}