import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../../../context";

export default function PrivateRoute({Component}){
    const isAuth = useContext(UserContext).isAuth;

    return(
        isAuth ? <Component /> : <Navigate to={"/login"} />
    );
}