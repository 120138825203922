import { createContext } from "react";
import { filesReducer } from "./reducers/files";

const INITIAL_STATE = {
    data: {
        items: [],
        hasMore: true,
        page: 1,
        firstApiCallAlreadyMade: false,
    },
    searchData: {
        items: [],
        hasMore: true,
        page: 1,
        firstApiCallAlreadyMade: false,
    },
    isSearch: false,
    loading: false
};

export const FilesContext = createContext(INITIAL_STATE);
export const FilesDispatchContext = createContext(filesReducer);
export const FILES_INITIAL_DATA = INITIAL_STATE;