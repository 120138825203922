import { useContext, useState } from "react";
import useGoBack from "./useGoBack";
import Papa from "papaparse";
import { validateKeyValue } from "../helpers/validators";
import { createEmails, getEmails } from "../api";
import { EmailsDispatchContext } from "../context/emails";
import useLoading from "./useLoading";
import { emailsActions as actions } from "../context/reducers/emails";

export default function useImportEmailsData(){
    const [state, setState] = useState({
        file: null,
        error: "",
        data: [],
        invalidRows: [],
        totalRows: []
    });

    const dispatch = useContext(EmailsDispatchContext);
    const loading = useLoading();
    let { goBack } = useGoBack();

    const fileName = state.file?.name || "";
    const invalidRecords = state.invalidRows.length;
    const totalRecords = state.data.length - invalidRecords;
    const realTotalRecords = state.totalRows;

    const fileChangeHandler = (event)=> {
        const files = event?.dataTransfer?.files || event?.target?.files;

        if(!files) return;

        const file = files[0];

        if(!file) return;

        setState(prev => ({
            ...prev,
            file
        }));

        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            worker: true,
            complete(results){
                const data = [];
                const invalidRows = [];
                const totalRows = results.data.length;

                results.data.slice(0, 1000).forEach((item, index) => {
                    item = typeof item === "object" ? Object.entries(item).reduce((res, [key, value]) => ({
                        ...res,
                        [key.toLowerCase()]: value
                    }), {}) : {};

                    const { email, company, gsm_company, name, gsm, address } = item || {};
                    
                    let invalidFields = [];

                    if(validateKeyValue("email", email)){
                        invalidFields.push("email");
                    }
                    
                    // if(validateKeyValue("name", company)){
                    //     invalidFields.push("company");
                    // }
                    
                    // if(validateKeyValue("gsm", gsm_company)){
                    //     invalidFields.push("gsm_company");
                    // }
                    
                    // if(typeof name === "string" && name.length > 0 && validateKeyValue("name", name)){
                    //     invalidFields.push("name");
                    // }
                    
                    // if(typeof gsm === "string" && gsm.length > 0 && validateKeyValue("gsm", gsm)){
                    //     invalidFields.push("gsm");
                    // }

                    if(invalidFields.length > 0){
                        invalidRows.push(index);
                    }

                    data.push({
                        email,
                        company: company || "",
                        gsm_company: gsm_company || "",
                        address: address || "",
                        gsm: gsm || "",
                        name: name || "",
                        invalidFields
                    });
                });
                    
                setState(prev => ({
                    ...prev,
                    error: results.errors.length > 0 ? "error while parsing file" : "",
                    data,
                    invalidRows,
                    totalRows
                }))
            }
        });
    }

    const submit = ()=> {
        if(state.data.length === 0) return;

        dispatch({
            type: actions.LOADING
        });

        const data = state.data.filter((_, index)=> !state.invalidRows.includes(index));

        if(data.length === 0) {
            return dispatch({
                type: actions.LOAD_ENDED
            });
        }

        createEmails(data, dispatch)
            .then(result => {
                if(!result){
                    goBack();
                    getEmails(1, dispatch);
                    return;
                }

                setState(prev => ({
                    ...prev,
                    error: result.message
                }));
            });
    }

    goBack = loading ? ()=> false : goBack;

    return { state, loading, fileName, invalidRecords, totalRecords, realTotalRecords, submit, fileChangeHandler, goBack };
}