import { deleteFile } from "../../../api";
import Modal from "../../../components/Modal";
import useFile from "../../../hooks/useFile";
import styles from "./delete.module.css";

export default function DeletePage(){
    const fileData = useFile();
    const { userId, fileId, file, loading, title, user, dispatch } = fileData;
    let { goBack } = fileData;

    if(!user || !userId || !file || !fileId) return null;

    const deleteButtonHandler = async (fileId)=> {
        if(loading) return;

        const result = await deleteFile(fileId, dispatch);
        
        if(!result){
            goBack();
        }
    }

    goBack = loading ? ()=> false : goBack;

    return(
        <Modal onClick={goBack}>
            <div onClick={(ev)=> ev.stopPropagation && ev.stopPropagation()} className={`custom-container ${styles["container"]}`}>
                <div className={`flex flex-col ${styles["header"]}`}>
                    <h3 className={styles.title}>are you sure you want to delete?</h3>
                    <div className={`flex flex-col items-center justify-center ${styles["content"]}`}>
                        <h2 className={styles["file-title"]} title={title}>{title}</h2>
                    </div>
                </div>
                <div className={`flex flex-row items-center justify-center ${styles["buttons"]}`}>
                    <button 
                        type="button"
                        value="cancel"
                        name="cancel"
                        className={`${styles["button"]} ${styles["cancel-button"]}`}
                        disabled={loading}
                        onClick={goBack}
                    >
                        cancel
                    </button>
                    <button 
                        type="button"
                        value="delete"
                        className={`${styles["button"]} ${styles["delete-button"]}`}
                        disabled={loading}
                        onClick={()=> deleteButtonHandler(fileId)}
                    >
                        delete
                    </button>
                </div>
            </div>
        </Modal>
    )
}