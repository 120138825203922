import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function useGoBack(){
    const navigate = useNavigate();
    const { state } = useLocation();
    const delta = state?.delta || -1;


    const goBack = useCallback(()=> {
        navigate(delta);
    }, [navigate, delta]);

    return { goBack, navigate };
}