import { useContext } from "react";
import { UserContext } from "../../../context";
import { Navigate } from "react-router-dom";

export default function NoAuthRoute({Component}){
    const isAuth = useContext(UserContext).isAuth;

    return(
        !isAuth ? <Component /> : <Navigate to={"/"} />
    );
}