import { useEffect, useState } from "react";
import useFile from "./useFile";
import { getFileDownloads, searchFileDownloads } from "../api";

export default function useFileDownloads(){
    const fileData = useFile();
    const { fileId, loading, dispatch } = fileData;

    const [state, setState] = useState({
        data: {
            items: [],
            hasMore: true,
            page: 1
        },
        searchData: {
            items: [],
            hasMore: true,
            page: 1
        },
        isSearch: false,
        search: {
            value: "",
            prevSearch: null,
        },
        firstApiCallAlreadyMade: false
    });

    useEffect(()=> {
        if(!fileId || loading || state?.firstApiCallAlreadyMade) return;

        async function getData(){
            const downloads = await getFileDownloads(fileId, 1, dispatch);

            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    items: Array.isArray(downloads?.data) ? downloads.data : prev.data.items,
                    hasMore: !!downloads.hasMore,
                    page: downloads.page || 1
                },
                isSearch: false,
                firstApiCallAlreadyMade: true
            }));
        };

        getData();
    }, [fileId, loading, state?.firstApiCallAlreadyMade, dispatch]);

    const setSearch = (value)=> {
        setState(prev => ({
            ...prev,
            search: {
                ...prev.search,
                value
            }
        }))
    }

    const searchHandler = ()=> {
        if(loading || (state.isSearch && state.search.value.trim() === state.search.prevSearch?.trim())){
            return;
        };
        
        if(state.search.value.trim().length === 0){
            resetMode();
            return;
        }
        
        searchFileDownloads(fileId, search.value.trim(), 1, dispatch)
            .then(downloads => {
                if(Array.isArray(downloads?.data)){
                    setState(prev => ({
                        ...prev,
                        searchData: {
                            ...prev.searchData,
                            items: downloads.data,
                            hasMore: downloads.hasMore,
                            page: downloads.page
                        },
                        isSearch: true,
                        firstApiCallAlreadyMade: true,
                        search: {
                            ...prev.search,
                            prevSearch: prev.search.value
                        }
                    }));
                }
            });
    }

    const resetMode = ()=> {
        if(loading) return;

        setState(prev => ({
            ...prev,
            isSearch: false,
            searchData: {
                items: [],
                hasMore: true,
                page: 1
            },
            search: {
                ...prev.search,
                prevSearch: null
            }
        }));
    }

    const { search, isSearch } = state;
    const downloads = (isSearch ? state.searchData : state.data) || {};
    const { hasMore, items: data, page } = downloads;

    return { ...fileData, data, hasMore, search, isSearch, page, setSearch, setState, dispatch, searchHandler, resetMode }
}