import { auth } from './api';
import Loader from './components/Loader';
import { USER_INITIAL_DATA, UserContext, UserDispatchContext } from './context';
import { useEffect, useReducer } from 'react';
import { Outlet } from 'react-router-dom';
import { FILES_INITIAL_DATA, FilesContext, FilesDispatchContext } from './context/files';
import { filesReducer } from './context/reducers/files';
import { EMAILS_INITIAL_DATA, EmailsContext, EmailsDispatchContext } from './context/emails';
import { emailsReducer } from './context/reducers/emails';
import { userReducer } from './context/reducers';

function App() {
  const [user, dispatchUser] = useReducer(
    userReducer,
    USER_INITIAL_DATA
  );

  const [files, dispatchFiles] = useReducer(
    filesReducer,
    FILES_INITIAL_DATA
  );

  const [emails, emailsDispatch] = useReducer(
    emailsReducer,
    EMAILS_INITIAL_DATA
  );

  const loading = user.loading || files.loading || emails.loading;

  useEffect(()=> {
    auth(dispatchUser);
  }, []);

  return (
    <UserContext.Provider value={user}>
      <UserDispatchContext.Provider value={dispatchUser}>
        <FilesContext.Provider value={files}>
          <FilesDispatchContext.Provider value={dispatchFiles}>
            <EmailsContext.Provider value={emails}>
              <EmailsDispatchContext.Provider value={emailsDispatch}>
                <Outlet />
                {loading && <Loader />}
              </EmailsDispatchContext.Provider>
            </EmailsContext.Provider>
          </FilesDispatchContext.Provider>
        </FilesContext.Provider>
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
