export async function CopyToClipboard(value){
    try{
        await navigator.clipboard.writeText(value);
        return true;
        // notify.success("copied!")
    }catch{
        return false;
        // notify.error("not copied")
    }
}

export function bytesToMB(bytes) {
    return bytes / (1024 * 1024);
}