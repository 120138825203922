import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import LoginPage from './routes/public/login';
import PublicRouteLayout from './routes/public';
import PrivateRouteLayout from './routes/private';
import Dashboard from './routes/private/dashboard';
import DeletePage from './routes/private/delete';
import CreatePage from './routes/private/create';
import UpdatePage from './routes/private/update';
import EmailsPage from './routes/private/emails';
import ImportEmailsPage from './routes/private/emails/import';
import FileDownloads from './routes/private/file-downloads';
import FileEmails from './routes/private/file-emails';
import ErrorElement from './routes/error';
import ZohoPage from './routes/private/zoho';

const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorElement />,
    children: [
      {
        path: "/",
        element: <PrivateRouteLayout />,
        children: [
          {
            path: "/",
            element: <Dashboard />,
            children: [
              {
                path: "emails-manager",
                element: <ZohoPage />
              },
              {
                path: "delete/:fileId",
                element: <DeletePage />
              },
              {
                path: "create",
                element: <CreatePage />
              },
              {
                path: "downloads-list/:fileId",
                element: <FileDownloads />
              },
              {
                path: "emails-list/:fileId",
                element: <FileEmails />
              },
              {
                path: "edit/:fileId",
                element: <UpdatePage />
              },
              {
                path: "emails",
                element: <EmailsPage />
              },
              {
                path: "emails/import",
                element: <ImportEmailsPage />
              }
            ]
          }
        ]
      },
      {
        path: "/login",
        element: <PublicRouteLayout />,
        children: [
          {
            path: "/login",
            element: <LoginPage />
          },
        ]
      },
      // {
      //   path: "/register",
      //   element: <PublicRouteLayout />,
      //   children: [
      //     {
      //       path: "/register",
      //       element: <RegisterPage />
      //     },
      //   ]
      // }
    ]
  }
])

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals(console.log);
