import EmailsItem from "../../../components/ListItem/emails";
import Modal from "../../../components/Modal";
import styles from "./emails.module.css";
import { ReactComponent as LoadMoreSvg } from "../../../components/svgs/loadmore.svg";
import useFileEmails from "../../../hooks/useFileEmails";

export default function FileEmails(){
    const { file, userId, title, data, updating, hasMore, search, isSearch, loading, setSearch, searchHandler, resetMode, approve, loadMore, goBack } = useFileEmails();

    if(!userId || !file) return null; 

    return(
        <Modal onClick={goBack} className={styles["modal"]}>
            <div onClick={(ev)=> ev.stopPropagation && ev.stopPropagation()} className={`flex flex-col custom-container ${styles["container"]}`}>
                <div className={`flex flex-col ${styles["header"]}`}>
                    <div className={`flex flex-col items-center justify-center ${styles["content"]}`}>
                        <h2 className={styles["file-title"]} title={title}>{title} - emails</h2>
                    </div>
                    <div className={`relative flex flex-row ${styles["search-input"]}`}>
                        <input 
                            type="text" 
                            placeholder="e.g. email, company name ..." 
                            value={search.value}  
                            onChange={(ev)=> setSearch(ev.target.value)}
                            disabled={loading}
                        />
                        <button onClick={searchHandler} disabled={loading}>
                            search
                        </button>
                        {
                            isSearch &&
                            <button onClick={resetMode} className={styles["reset"]} disabled={loading}>
                                reset
                            </button>
                        }
                    </div>
                </div>
                <div className={`flex flex-col ${styles["emails-wrapper"]}`}>
                    <div className={`flex flex-row items-start ${styles["emails-container"]}`}>
                        <table className={`custom-container ${styles["emails"]}`}>
                            <thead>
                                <tr className={`${styles["email"]} ${styles["head"]}`}>
                                    <th>email</th>
                                    <th>company</th>
                                    <th>company gsm</th>
                                    <th>name</th>
                                    <th>gsm</th>
                                    <th>address</th>
                                    <th>approved</th>
                                    <th>verified</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Array.isArray(data) &&
                                    data.map((fileEmail) => {
                                        if(typeof fileEmail !== "object" || typeof fileEmail.email !== 'object') return null;
                                        const { email, emailId, fileId, approved } = fileEmail;
                                        const id = `${emailId}-${fileId}`;
                                        console.log(email);
                                        return(
                                            <EmailsItem 
                                                key={id}
                                                item={{...email, approved: !!approved}}
                                                approving={updating.has(id) && updating.get(id)}
                                                onChange={(approved)=> approve(fileId, emailId, approved)}
                                            />
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    {
                        hasMore &&
                        <section className="flex flex-row justify-center items-center">
                            <button
                                type="button"
                                value={"load more"}
                                className={`flex flex-row items-center ${styles["load-more-button"]}`}
                                onClick={loadMore}
                            >
                                <LoadMoreSvg />
                                <span>load more</span>
                            </button>
                        </section>
                    }
                </div>
                <div className={`flex flex-row items-center justify-end ${styles["buttons"]}`}>
                    <button 
                        type="button"
                        value="cancel"
                        name="cancel"
                        className={`${styles["button"]} ${styles["cancel-button"]}`}
                        onClick={goBack}
                    >
                        close
                    </button>
                </div>
            </div>
        </Modal>
    );
}