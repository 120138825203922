import { useContext } from "react";
import { UserContext } from "../context";
import { FilesContext } from "../context/files";
import { EmailsContext } from "../context/emails";

export default function useLoading(){
    const { loadingUser } = useContext(UserContext);
    const { loadingFiles } = useContext(FilesContext);
    const { loadingEmails } = useContext(EmailsContext);

    return loadingUser || loadingFiles || loadingEmails;
}